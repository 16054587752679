import { FC } from "react"

import { Button, Descriptions, Empty, Skeleton, Space } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import { CustomersByClientTable } from "@/components/Customers/CustomersByClientTable"
import { Resource, Scope } from "@/constants/permission"

import classes from "./CustomerClientById.module.scss"

import { useCustomerClientByIdQuery } from "@/hook/CustomerClients/useCustomerClientQuery"
import { usePermission } from "@/hook/usePermission"

import { paths } from "@/router/paths"


const { Item } = Descriptions

const CustomerClientById: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  const { data, isLoading, isError } = useCustomerClientByIdQuery(Number(id))
  
  const { hasPermission } = usePermission()
  const hasPermissionToCustomersTable = hasPermission(Resource.CustomerByClient, Scope.Read)
  const hasPermissionToCustomerClientsList = hasPermission(Resource.Customer, Scope.Menu)

  const onBackHandler = () => {
    navigate(-1)
  }
  const onBackToListHandler = () => {
    navigate(paths.customerClients)
  }

  if (isError) {
    return <Empty description="Клиент не найден" />
  }

  return (
    <Skeleton loading={isLoading}>
      {data && (
        <Space direction="vertical" className={classes["wrapper"]}>
          <Descriptions
            title={`${data.response.name}`}
            layout="vertical"
            bordered
          >
            <Item label="Категория">{data.response.customerCategory.name}</Item>
            <Item label="ФИО отвественного лица">{data.response.responsiblePersonName}</Item>
            <Item label="Номер телефона">+7 {data.response.phone}</Item>
          </Descriptions>
          { hasPermissionToCustomersTable && 
          <CustomersByClientTable clientId={Number(id)}></CustomersByClientTable>
          }
          <Space className={classes["buttons"]}>
            <Button onClick={onBackHandler}>Назад</Button>
            {hasPermissionToCustomerClientsList && (
              <Button onClick={onBackToListHandler}>Назад к списку</Button>
            )}
          </Space>
        </Space>
      )}
    </Skeleton>
  )
}

export default CustomerClientById
