import { FC, useState } from "react"

import { Checkbox, Flex, Form, Segmented, Select } from "antd"
import { CartesianGrid, Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts"

import { Table } from "@/components/_Shared/Table"
import { Resource } from "@/constants/permission"

import { orderReportColumns, periods, deliveries } from "./OrderReportColumns"
import classes from "./OrderReportPage.module.scss"

import { useCustomerQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"
import { useOrderReportQuery } from "@/hook/Dictionaries/useOrderReportQuery"
import { useTable } from "@/hook/useTable"

import { DeliveryType } from "@/types/IOrder"
import { ORDER_REPORT_KEY, Period } from "@/types/IOrderReport"

import { getInitialColumns } from "@/utils/getInitialColumns"

const OrderReportPage: FC = () => {
  const [activePeriod, setActivePeriod] = useState<Period>(Period.Yearly)
  const [activeDeliveryType, setActiveDeliveryType] = useState<DeliveryType>()
  const [customerId, setCustomerId] = useState<number>()
  const [isNotShipped, setIsNotShipped] = useState<boolean>(false)

  const { columns, pagination, filter, order } = useTable({
    initialColumns: getInitialColumns(orderReportColumns, ORDER_REPORT_KEY),
    key: ORDER_REPORT_KEY
  })

  const { data, isLoading } = useOrderReportQuery({
    period: activePeriod,
    customerId: customerId,
    deliveryType: activeDeliveryType,
    isNotShipped: isNotShipped == true ? isNotShipped : undefined,
    Page: pagination.page,
    PageSize: pagination.pageSize,
    Filter: filter.filter,
    OrderBy: order.orderBy
  })

  const { data: customers } = useCustomerQuery()

  const onCustomerChange = (id: number) => {
    setCustomerId(id)
  }

  const chartConfig = {
    xField: "period",
    yField: "orderCount"
  }

  return (
    <>
      <Flex gap="small" align="flex-start" vertical>
        <Segmented
          options={periods}
          onChange={(e) => setActivePeriod(Number(e))}
        />
        <Segmented
          options={deliveries}
          onChange={(e) =>
            setActiveDeliveryType(e == -1 ? undefined : Number(e))
          }
        />

        <Flex gap="middle" align="baseline">
          <Form.Item
            className={classes.customerSelect}
            name="Контрагент"
            label="Контрагент"
          >
            <Select
              onClear={() => setCustomerId(undefined)}
              onChange={onCustomerChange}
              placeholder="Контрагент"
              showSearch
              allowClear
              options={customers?.response.data.map((val) => ({
                value: val.id,
                label: val.name
              }))}
            />
          </Form.Item>
          <Checkbox onChange={(e) => setIsNotShipped(e.target.checked)}>
            Только неотгруженные заказы
          </Checkbox>
        </Flex>
        <LineChart width={700} height={400} data={data?.response.data} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
          <XAxis dataKey={chartConfig.xField} />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid stroke="#f5f5f5" />
          <Line type="monotone" dataKey={chartConfig.yField} stroke="#387908" />
        </LineChart>

        <PieChart width={730} height={250}>
          <Pie data={data?.response.data} dataKey={chartConfig.yField} nameKey="Count" fill="#8884d8" label/>
        </PieChart>
        <Table
          dataSource={data?.response.data}
          columns={columns.data}
          isSelectable={false}
          rowKey="period"
          isLoading={isLoading}
          onUpdateColumns={columns.onColumnChange}
          pagination={{
            currentPage: pagination.page,
            pageSize: pagination.pageSize,
            total: data?.response.count,
            onPageChange: pagination.onPageChange,
            onPageSizeChange: pagination.onPageSizeChange
          }}
          onSorterChange={order.setOrderBy}
          permission={{
            resource: Resource.OrderReport
          }}
        />
      </Flex>
    </>
  )
}

export default OrderReportPage
