import { FC } from "react"

const AnalyticsRefusalPage:FC = ()=>{
    return (
        <div>
            <h2>Динамика отказов</h2>
        </div>
    )
}

export default AnalyticsRefusalPage