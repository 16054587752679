export const FUEL_TYPE_QUERY_KEY = "fuelTypes"
export const DELIVERY_ADDRESS_QUERY_KEY = "delivery-addresses"
export const DELIVERY_ADDRESS_FOR_ORDER_QUERY_KEY = "addresses/order"
export const DRIVER_QUERY_KEY = "drivers"
export const DRIVER_FOR_ORDER_QUERY_KEY = "drivers/order"
export const CUSTOMER_QUERY_KEY = "customers"
export const CUSTOMER_SELECT_LIST_QUERY_KEY = "customers/selectList"
export const CUSTOMER_CLIENT_QUERY_KEY = "customer-clients"
export const CUSTOMER_CATEGORY_QUERY_KEY = "customer-categories"
export const CUSTOMER_CATEGORY_SUM_QUERY_KEY = "customer-categories/sum"
export const CUSTOMER_CATEGORY_CARD_QUERY_KEY = "customer-categories/card"
export const TRANSPORT_QUERY_KEY = "transports"
export const TRANSPORT_FOR_ORDER_QUERY_KEY = "transports/order"
export const TRANSPORT_TYPE_QUERY_KEY = "transportTypes"
export const TRANSPORT_MODELS_QUERY_KEY = "transportModels"
export const DEPOT_QUERY_KEY = "depots"
export const DEPOT_LIST_QUERY_KEY = "depotsList"
export const FUEL_QUERY_KEY = "fuels"
export const FUEL_QUOTES_QUERY_KEY = "fuelQuotes"
export const FUEL_QUOTES_SETTINGS_QUERY_KEY = "fuelQuotesCategorySettings"
export const FUEL_LIMITS_QUERY_KEY = "fuelLimits"
export const FUEL_LIMITS_SETTINGS_QUERY_KEY = "fuelLimitsCategorySettings"
export const ORDER_QUERY_KEY = "orders"
export const ORDER_LOGS_QUERY_KEY = "orders/logs"
export const ORDER_DISPATCHER_QUERY_KEY = "dispatcherOrders"
export const ORDER_DISPATCHER_TIME_QUERY_KEY = "dispatcherOrders_timeCoordinate"
export const ORDER_REPORT_QUERY_KEY = "order/reports"
export const ORDER_STATUS_QUERY_KEY = "orders/statuses"
export const DELIVERY_TYPE_QUERY_KEY = "orders/delivery-types"
export const EXCHANGE_TYPE_QUERY_KEY = "orders/exchange-types"
export const PAYMENT_STATUS_QUERY_KEY = "orders/payment-statuses"
export const USER_QUERY_KEY = "users"
export const USER_ADMIN_QUERY_KEY = "users/admin"
export const ROLE_QUERY_KEY = "roles"
export const ROLE_LIST_QUERY_KEY = "roles/list"
export const FINANCE_QUERY_KEY = "finance"
export const COUNTRY_QUERY_KEY = "countries"
export const AUTHORITIES_QUERY_KEY = "authorities"
export const PROVIDER_QUERY_KEY = "providers"
export const TIME_COORDINATE_QUERY_KEY = "time-coordinate"
export const TIME_UNLOAD_FUEL_FREE_KEY = "timeUnloadFuel/free"
export const TIME_UNLOAD_FUEL_AVAILABLE_KEY = "timeUnloadFuel/available"
export const WAYBILL_QUERY_KEY = "waybills"
export const NOTIFICATION_SETTINGS_QUERY_KEY = "notificaitonSettings"
export const NOTIFICATION_QUERY_KEY = "notificaitons"
export const NOTIFICATION_NOT_READ_QUERY_KEY = "notificaitons/not-read"
export const NOTIFICATION_NOT_READ_COUNT_QUERY_KEY = "notificaitons/not-read-count"
export const ANALYTICS_PRICE_QUERY_KEY = "analytics/ptice"
export const ANALYTICS_COMPARISON_PERIOD_QUERY_KEY = "analytics/comparison-period"
export const ANALYTICS_QUERY_KEY = "analytics"
export const ANALYTICS_ORDERS_QUERY_KEY = "analytics/get-order-analytics"