import { ISelectListItem } from "@/types/ui"

export interface ICustomer {
	id: number
	name: string
	balance: number
	sumOrder: number
	availableSum: number
	dayLimit: number
	customerClientName: string
	customerClientId: number
	inn: string
	kpp: string
	ogrn: string
	postAddress: ICustomerAddress
	legalAddress: ICustomerAddress
	phone: string
}

export interface IDispatcherCustomer {
	id: number
	name: string
	inn: string
	legalAddress: ICustomerAddress
}

export interface ICustomerAddress {
	buildingNumber: number
	countryId: 1
	district: string
	city: string
	postCode: string
	region: string
	roomNumber: number
	settlement: string
	street: string
}

export interface ICustomerByClient {
	id: number
	name: string
	inn: string
	ogrn: string
	phone: string
	customerUsers: ICustomerUser[]
}

export interface ICustomerCreate {
	name: string
	balance: number
	dayLimit: number
	inn: string
	kpp: string
	ogrn: string
	postAddress: string
	legalAddress: string
	phone: string
	customerClientId: number
}

export interface ICustomerUpdate extends ICustomerCreate {
	id: number
}

export const CUSTOMERS_KEY = "CUSTOMERS_KEY"

export interface ICustomerUser {
	userId: string
	userName: string
	customerId: number
}

export interface ICustomerSelectListItem extends ISelectListItem {
	customerClientName: string
	customerClientId: number
}

export const getTransformedCompanyName = (customer: ICustomer | IDispatcherCustomer | undefined | null) => {
	if (!customer)
		return

	const address = getTransformedAddress(customer.legalAddress)
	const addressToAdd = address === ""
		? ""
		: `, ${address}`
	const innToAdd = !customer.inn || customer.inn === ""
		? ""
		: `, ${customer.inn}`

	return `"${customer.name}"${innToAdd}${addressToAdd}`
}

export const getTransformedAddress = (address: ICustomerAddress | undefined | null) => {
	if (!address)
		return ""

	let parts = []

	if (!address.postCode || address.postCode === ""
		|| !address.region || address.region === ""
		|| !address.city || address.city === ""
		|| !address.street || address.street === ""
		|| !address.buildingNumber || address.buildingNumber <= 0)
		return ""

	parts.push(address.postCode)
	parts.push(address.region)
	parts.push("г. " + address.city)
	parts.push("ул. " + address.street)
	parts.push("дом " + address.buildingNumber)

	if (!address.roomNumber || address.roomNumber <= 0)
		return parts.join(", ")

	parts.push(`к. ${address.roomNumber}`)

	return parts.join(", ")
}