import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react"

import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
  UploadFile
} from "antd"
import dayjs from "dayjs"
import { useSearchParams } from "react-router-dom"
import { uid } from "uid"

import { DatePicker } from "@/components/_Shared/DatePicker"
import { PicturesUpload } from "@/components/_Shared/PicturesUpload"
import { CustomerContext } from "@/context/CustomerContext"
import { getCustomerClientsSelectList } from "@/http/customerClient"

import { useTransportModelsQuery } from "@/hook/Dictionaries/useTransportModelQuery"
import { useTransportByIdQuery } from "@/hook/Dictionaries/useTransportQuery"
import { useTransportsMutation } from "@/hook/Dictionaries/useTransportsMutation"
import { useTransportTypesQuery } from "@/hook/Dictionaries/useTransportTypeQuery"

import {
  ITransport,
  ITransportClass,
  ITransportCreate,
  ITransportType,
  TransportClassForSelect,
  IDocumentFile
} from "@/types/ITransport"
import { ISelectListItem } from "@/types/ui"

import { filterSelectOption } from "@/utils/filterSelectOption"
import { uploadFileConvert } from "@/utils/uploadFileConvert"

const { Text } = Typography

export interface TransportAddFormProps {
  open(id?: number): void

  close(): void
}

interface TransportAddFormValues {
  modelId: number
  typeId: number
  modelName: string
  typeName: string
  classType: ITransportClass
  number: string
  type: ITransport
  region: string
  brand: string
  color: string
  vin: string
  owner: string
  weightWithoutLoad: number
  permittedMaximumMass: number
  axlesNumber: number
  liter: number
  calibrationCertificateNumber: string
  verificationCertificateNumber: string
  dateExpirationCertificate: string
  dateBeginningCertificate: string
  section1: number
  section2: number
  section3: number
  section4: number
  section5: number
  section6: number
  section7: number
  section8: number
  clientIds: number[]
  stsScans?: IDocumentFile[]
}

export const TransportAddForm = forwardRef<TransportAddFormProps>(
  (props, ref) => {
    const customerContext = useContext(CustomerContext)

    const [form] = Form.useForm<TransportAddFormValues>()
    const [id, setId] = useState<number>(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [modalText, setModalText] = useState("")

    const [data, setData] = useState<TransportAddFormValues>()
    const [clients, setClients] = useState<ISelectListItem[]>([])

    const { data: transport, isLoading } = useTransportByIdQuery(id)
    const { data: transportTypes } = useTransportTypesQuery()
    const { data: transportModels } = useTransportModelsQuery()
    const [transportTypesForSelect, setTransportTypesForSelect] = useState<
      ITransportType[]
    >(transportTypes?.response.data ?? [])
    const [ isTruck, setIsTruck ] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])

    
    const { createMutation, updateMutation } = useTransportsMutation()

    useImperativeHandle(ref, () => ({
      open(id?: number) {
        onOpenHandler(id)
      },
      close() {
        onCloseHandler()
      }
    }))

    useEffect(() => {
      if (transport?.response?.stsScans) {
        const newPromises = transport.response.stsScans.map((file) =>
          uploadFileConvert({
            url: file.content,
            name: file.name,
            uid: uid()
          })
        )
        Promise.all(newPromises).then((res) => {
          onFileListChange(res)
        })
      }
    }, [transport?.response.stsScans])

    useEffect(() => {
      if (isOpen && !customerContext.isCustomer()) {
        getCustomerClientsSelectList().then((res) => setClients(res.response))
      }

      if (transport && isOpen) {
        const response = transport.response
        form.setFields([
          {
            name: "number",
            value: response.number
          },
          {
            name: "class",
            value: response.class
          },
          {
            name: "modelId",
            value: response.modelId
          },
          {
            name: "typeId",
            value: response.typeId
          },
          {
            name: "region",
            value: response.region
          },
          {
            name: "brand",
            value: response.brand
          },
          {
            name: "color",
            value: response.color
          },
          {
            name: "vin",
            value: response.vin
          },
          {
            name: "owner",
            value: response.owner
          },
          {
            name: "weightWithoutLoad",
            value: response.weightWithoutLoad
          },
          {
            name: "permittedMaximumMass",
            value: response.permittedMaximumMass
          },
          {
            name: "axlesNumber",
            value: response.axlesNumber
          },
          {
            name: "liter",
            value: response.liter
          },
          {
            name: "calibrationCertificateNumber",
            value: response.calibrationCertificateNumber
          },
          {
            name: "verificationCertificateNumber",
            value: response.verificationCertificateNumber
          },
          {
            name: "dateBeginningCertificate",
            value:
              response.dateExpirationCertificate.length > 0
                ? dayjs(response.dateBeginningCertificate, "DD/MM/YYYY")
                : null
          },
          {
            name: "dateExpirationCertificate",
            value:
              response.dateExpirationCertificate.length > 0
                ? dayjs(response.dateExpirationCertificate, "DD/MM/YYYY")
                : null
          },
          {
            name: "section1",
            value: response.section1
          },
          {
            name: "section2",
            value: response.section2
          },
          {
            name: "section3",
            value: response.section2
          },
          {
            name: "section4",
            value: response.section4
          },
          {
            name: "section5",
            value: response.section5
          },
          {
            name: "section6",
            value: response.section6
          },
          {
            name: "section7",
            value: response.section7
          },
          {
            name: "section8",
            value: response.section8
          }
        ])

        form.setFieldValue("clientIds", response.clients.map(c => c.id))

        setTransportTypesForSelect(
          transportTypes?.response.data.filter(
            (t) => t.class === response.class
          ) ?? []
        )
      }
    }, [id, transport, form, isOpen])

    const formTransportText = useMemo(() => {
      return id === 0 ? "Внести" : "Изменить"
    }, [id])

    const title = useMemo(() => {
      if (id) {
        return "Редактировать"
      }

      return "Добавить"
    }, [id])

    const setOrDeleteQueryParams = (name: string, value?: string) => {
      if (value) searchParams.set(name, value)
      else searchParams.delete(name)

      setSearchParams(searchParams)
    }

    const onOpenHandler = (id?: number) => {
      if (id) {
        setId(id)
      }
      form.resetFields()
      setIsOpen(true)
      setOrDeleteQueryParams("transportAddForm", "true")
    }
    const onTransportClassChange = (e: number) => {
      setTransportTypesForSelect(
        transportTypes?.response.data.filter((t) => t.class === e) ?? []
      )
      let transportClass = form.getFieldValue("class")

      if (transportClass == ITransportClass.Truck) {
        setIsTruck(true)
      }
      else {
        setIsTruck(false)
      }

      form.setFieldValue("typeId", undefined)
      form.setFieldValue("modelId", undefined)
    }

    const onFileListChange = (file: UploadFile | UploadFile[]) => {
      if (Array.isArray(file)) {
        setFileList(file)
        if (file.length > 0) {
          form.setFieldValue("stsScan", file.length)
        }
        else {
          // Если не будет файлов, кидает ошибку валидации.
          // Нужно потому что длину 0 тоже воспринимает как значение.
          // А на самом деле это не должно быть валидным.
          form.setFieldValue("stsScan", undefined)
        }
      } else {

        setFileList((prevState) => [...prevState, file])
        form.setFieldValue("stsScan", fileList.length)
      }
    }
    const onTransportTypeChange = (e: number) => {}
    const onTransportModelChange = (e: number) => {}

    const tryTransport = async (data: TransportAddFormValues | undefined) => {
      try {
        if (data === undefined) {
          return
        }

        const request: ITransportCreate = {
          modelId: data.modelId,
          typeId: data.typeId,
          modelName: data.modelName,
          typeName: data.typeName,
          classType: data.classType,
          number: data.number,
          approved: true,
          region: data.region,
          brand: data.brand,
          color: data.color,
          vin: data.vin,
          owner: data.owner,
          weightWithoutLoad: data.weightWithoutLoad,
          permittedMaximumMass: data.permittedMaximumMass,
          axlesNumber: data.axlesNumber,
          liter: data.liter,
          calibrationCertificateNumber: data.calibrationCertificateNumber,
          verificationCertificateNumber: data.calibrationCertificateNumber,
          dateExpirationCertificate: data.dateExpirationCertificate,
          dateBeginningCertificate: data.dateBeginningCertificate,
          section1: data.section1,
          section2: data.section2,
          section3: data.section3,
          section4: data.section4,
          section5: data.section5,
          section6: data.section6,
          section7: data.section7,
          section8: data.section8,
          stsScans: fileList.map((file) => ({
            content: (file.url as string).split("base64,")[1],
            name: file.name
          })), 
          clientIds: customerContext.isCustomer()
            ? customerContext.customer?.customerClientId
              ? [customerContext.customer?.customerClientId]
              : []
            : data.clientIds
        }
        setIsProcessing(true)

        if (id) {
          await updateMutation.mutateAsync({
            ...request,
            id: id
          })
        } else {
          await createMutation.mutateAsync(request)
        }

        form.resetFields()
        onCloseHandler()
      } finally {
        setIsProcessing(false)
      }
    }
    const onFinish = (data: TransportAddFormValues) => {
      setData(data)
      setModalTitle("Предупреждение")
      setModalText(`Просим Вас проверить данные согласно СТС, в случае если данные указаны не корректно или не соответствуют данным в документе
      – в записи на нефтебазу будет отказано!)`)
      setIsModalOpen(true)
    }

    const onCloseHandler = () => {
      setId(0)
      form.resetFields()
      setData(undefined)
      setIsOpen(false)
      setOrDeleteQueryParams("transportAddForm")
    }

    const handleOk = async () => {
      setIsModalOpen(false)
      await tryTransport(data)
    }

    const handleCancel = () => {
      setIsModalOpen(false)
    }

    return (
      <Drawer
        title={title}
        width={820}
        onClose={onCloseHandler}
        open={isOpen}
        styles={{ body: { paddingBottom: 80 } }}
        extra={
          <Button type="primary" disabled={isProcessing} onClick={form.submit}>
            {formTransportText}
          </Button>
        }
      >
        <Modal
          title={modalTitle}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Text>{modalText}</Text>
        </Modal>
        <Skeleton loading={id === 0 ? false : isLoading}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              hidden={customerContext.isCustomer()}
              name="clientIds"
              label="Клиент"
              rules={[
                {
                  required: !customerContext.isCustomer(),
                  message: "Пожалуйста выберите клиента"
                }
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Клиент"
                showSearch
                options={clients}
              />
            </Form.Item>
            <Form.Item
              name="class"
              label="Вид транспорта"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста выберите вид транспорта"
                }
              ]}
            >
              <Select
                onChange={(e: number) => onTransportClassChange(e)}
                placeholder="Вид транспорта"
                showSearch
                filterOption={(inputValue, option) =>
                  filterSelectOption(inputValue, option?.label || "")
                }
                options={TransportClassForSelect.map((type) => ({
                  value: type.id,
                  label: type.value
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Гос.номер"
              name="number"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста введите гос.номер"
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="region"
              label="Регион"
              rules={[{ required: true, message: "Пожалуйста введите регион" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="brand"
              label="Марка"
              rules={[{ required: true, message: "Пожалуйста введите марку" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Модель"
              name="modelId"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста введите Модель"
                }
              ]}
            >
              <Select
                onChange={(e: number) => onTransportModelChange(e)}
                showSearch
                options={transportModels?.response.data.map((type) => ({
                  value: type.id,
                  label: type.name
                }))}
                filterOption={(inputValue, option) =>
                  filterSelectOption(inputValue, option?.label || "")
                }
              />
            </Form.Item>
            <Form.Item
              label="Цвет"
              name="color"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста введите цвет"
                }
              ]}
            >
              <Input type="text" placeholder="Цвет" maxLength={17} />
            </Form.Item>
            <Form.Item
              normalize={(value) => value.toUpperCase()}
              label="VIN"
              name="vin"
              rules={[
                {
                  required: true,
                  message:
                    "Пожалуйста введите VIN (в случае отсутствия, указать номер шасси)"
                }
              ]}
            >
              <Input
                type="text"
                placeholder="VIN (в случае отсутствия, указать номер шасси)"
                maxLength={17}
              />
            </Form.Item>
            <Form.Item
              label="Тип транспорта"
              name="typeId"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста выберите тип владельца"
                }
              ]}
            >
              <Select
                onChange={(e: number) => onTransportTypeChange(e)}
                showSearch
                options={transportTypesForSelect?.map((type) => ({
                  value: type.id,
                  label: type.name
                }))}
                filterOption={(inputValue, option) =>
                  filterSelectOption(inputValue, option?.label || "")
                }
              />
            </Form.Item>

            <Form.Item
              label="Собственник"
              name="owner"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста введите собственника"
                }
              ]}
            >
              <Input placeholder="Собственник" />
            </Form.Item>
            <Form.Item
              name="weightWithoutLoad"
              label="Масса без нагрузки"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста введите разрешённую максимальную массу"
                }
              ]}
            >
              <InputNumber<number>
                min={0}
                step={1}
                style={{ width: "100%" }}
                formatter={(value) => `${value}кг.`}
                parser={(value) => Number(value!.replace("кг.", ""))}
              />
            </Form.Item>

            <Form.Item
              name="permittedMaximumMass"
              label="Разрешенная максимальная масса"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста введите разрешённую максимальную массу"
                }
              ]}
            >
              <InputNumber<number>
                min={0}
                step={1}
                style={{ width: "100%" }}
                formatter={(value) => `${value}кг.`}
                parser={(value) => Number(value!.replace("кг.", ""))}
              />
            </Form.Item>

            <Form.Item
              name="axlesNumber"
              label="Количество осей"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста введите количество осей"
                }
              ]}
            >
              <InputNumber
                placeholder="Количество осей"
                type={"number"}
                parser={(value) => parseInt(value || "0", 10)}
                style={{ width: "100%" }}
                min={0}
                step={1}
              />
            </Form.Item>
            <Form.Item
              hidden={ isTruck }
              name="calibrationCertificateNumber"
              label="Номер сертификата о калибровке"
              rules={[
                {
                  required: !isTruck,
                  message: "Пожалуйста введите номер сертификата о калибровке"
                }
              ]}
            >
              <Input placeholder="Номер сертификата о калибровке" />
            </Form.Item>
            <Form.Item
              hidden={ isTruck }
              name="verificationCertificateNumber"
              label="Номер сертификата о проверке"
              rules={[
                {
                  required: !isTruck,
                  message: "Пожалуйста введите номер сертификата о проверке"
                }
              ]}
            >
              <Input placeholder="Номер сертификата о проверке" />
            </Form.Item>
            <Form.Item
              hidden={ isTruck }
              label="Дата начала действия свидетельства о поверке (сертификата о калибровке)"
              name="dateBeginningCertificate"
              rules={[
                {
                  required: !isTruck,
                  message:
                    "Пожалуйста введите дату начала действия свидетельства о поверке (сертификата о калибровке)"
                }
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Дата начала действия свидетельства о поверке (сертификата о калибровке)"
              />
            </Form.Item>
            <Form.Item
              hidden={ isTruck }
              label="Дата окончания действия свидетельства о поверке (сертификата о калибровке)"
              name="dateExpirationCertificate"
              rules={[
                {
                  required: !isTruck,
                  message:
                    "Пожалуйста введите дату окончания действия свидетельства о поверке (сертификата о калибровке)"
                }
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Дата окончания действия свидетельства о поверке (сертификата о калибровке)"
              />
            </Form.Item>
            <Form.Item
              hidden={ isTruck }
              name="liter"
              label="Литраж"
              rules={[
                {
                  required: !isTruck,
                  message: "Пожалуйста введите литраж"
                }
              ]}
            >
              <InputNumber
                placeholder="Литраж"
                type={"number"}
                style={{ width: "100%" }}
                min={0}
                step={1}
              />
            </Form.Item>
            <Row gutter={16} hidden={ isTruck }>
              {Array.from({ length: 8 }, (_, i) => i + 1).map((index) => (
                <Col key={index} xs={24} md={12} >
                  <Form.Item
                    initialValue={0}
                    name={`section${index}`}
                    label={`Секция ${index}`}
                    rules={[
                      {
                        required: !isTruck,
                        message: `Пожалуйста заполните секцию ${index}`
                      }
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Form.Item
            name="stsScan"
            label="Скан СТС "
            rules={[
              {
                required: true,
                message: "Пожалуйста загрузите скан СТС "
              }
            ]}
          >
            <PicturesUpload 
            onChangeHandler={onFileListChange}
            fileList={fileList} />
          </Form.Item>
          </Form>
        </Skeleton>
      </Drawer>
    )
  }
)
