export const formatNumber = (value: number, digits: number = 2) => {
    return (Math.round(value * 100) / 100).toFixed(digits)
}

export const formatFuelAmount = (value: number) => {
    return Number(value.toFixed(6))
}

export const formatFuelVolume = (value: number) => {
    return Number(value.toFixed(2))
}

export const getMoney = (value: number, digits: number = 2) => {
    let valueStr: string = value.toString()
    if (value % 1 > 0) {
        valueStr = value.toFixed(digits)
    }
    return valueStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₽"
}

export const getPositiveOrZero = (value: number) => {
    return value < 0 ? 0 : value
}
