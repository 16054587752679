import { FC, useEffect, useMemo } from "react"

import { OidcSecure } from "@axa-fr/react-oidc"
import { Layout,notification } from "antd"
import { Outlet } from "react-router-dom"

import { Header } from "@/components/_Shared/Layout/Header"
import { Sidebar } from "@/components/_Shared/Layout/Sidebar"
import { useSignalR } from "@/context/SignalRContext"
import { WithSuspense } from "@/hoc/WithSuspense"

import classes from "./MainLayout.module.scss"

import { INotification } from "@/types/INotificaiton"


export const MainLayout: FC = () => {
  const [ notificationApi, notificationContextHolder ] = notification.useNotification()
  const connection = useSignalR()

  useEffect( () => {    
    showNotReadNotificatons()

    connection?.on("ReciveNotification", (message) => {
      notificationApi.info({
        message : "Уведомление",
        description : message,
        placement: "top",
        duration: 20   
      })
    })
  })

  const showNotReadNotificatons = async () =>{
    const notifications = await connection?.invoke<INotification[]>("GetNotReadNotification") ?? []

    for(const notificaiton of notifications){
      notificationApi.info({
        message : "Уведомление",
        description : notificaiton.message,
        placement: "top",
        duration: 20   
      })
    }
  }

  return (
  <Layout className={classes.layout}>
    <Sidebar />
    <Layout>
      <Header />
      <Layout.Content className={classes.content}>
        <div className={classes["page-wrapper"]}>
          {notificationContextHolder}
          <WithSuspense>
            <OidcSecure>
              <Outlet />
            </OidcSecure>
          </WithSuspense>
        </div>
      </Layout.Content>
    </Layout>
  </Layout>)
}