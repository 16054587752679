import { DiagramAnalytics } from "@/types/IOrderAnalytics"
import { ITableColumn } from "@/types/ui"

import { formatFuelAmount, getMoney } from "@/utils/formatNumber"

const optionalColumns: ITableColumn<DiagramAnalytics>[] = [
  {
    title: "Объем заказов",
    key: "orderAmount",
    dataIndex: ["orderAmount"],
    render: (_, { orderAmount }) => formatFuelAmount(orderAmount),
    sorter: true,
    isVisible: true,
    order: 3
  },
  {
    title: "Сумма заказов",
    key: "orderSum",
    dataIndex: ["orderSum"],
    render: (_, { orderSum }) => <><span>{getMoney(orderSum)}</span></>,
    sorter: true,
    isVisible: true,
    order: 4
  },
  {
    title: "Сумма заказов (млн)",
    key: "orderSumMillions",
    dataIndex: ["orderSumMillions"],
    render: (_, { orderSumMillions }) => <><span>{getMoney(orderSumMillions, 3)}</span></>,
    sorter: true,
    isVisible: true,
    order: 5
  }
]

// Если брать с mode и без, то изменяя optionalColumns в одной коллекции, изменится и в другом
export const orderAnalyticsColumns = (mode?: string): ITableColumn<DiagramAnalytics>[] => [
  {
    title: "Период",
    key: "period",
    dataIndex: ["period"],
    sorter: true,
    isVisible: true,
    order: 1
  },
  {
    title: "Количество заказов",
    key: "orderCount",
    dataIndex: ["orderCount"],
    sorter: true,
    isVisible: true,
    order: 2
  },
  ...(mode
    ? optionalColumns.filter(c => c.key === mode)
    : optionalColumns)
]
