import { ICustomer, IDispatcherCustomer } from "./ICustomer"
import { IDelivery } from "./IDelivery"
import { IDeliveryAddress } from "./IDeliveryAddress"
import { IDepot } from "./IDepot"
import { IDriver } from "./IDriver"
import { ITransport } from "./ITransport"

export interface IOrder {
  id: number
  number: string
  date: string
  status: number
  statusText: string
  deliveryType: number
  deliveryTypeText: string
  delivery: IDelivery
  exchangeType?: number
  exchangeTypeText?: string
  deliveryPrice: number
  orderSumm: number
  orderAmount: number
  paymentSumm: number
  paymentStatus: number
  paymentStatusText: string
  wishTimeSlot?: string
  isTimeAgreed?: boolean
  customer: ICustomer
  depot: IDepot
  depotId: number
  transports: ITranportPairOrder[]
  fuelQuotes: IOrderFuel[]
  operationType: number
  operationTypeText: string
  createdOn: Date,
  actualLoadTime: Date
}

export interface IDispatcherOrder {
  id: number
  number: string
  date: string
  status: number
  statusText: string
  deliveryType: number
  deliveryTypeText: string
  delivery: IDelivery
  exchangeType?: number
  exchangeTypeText?: string
  orderAmount: number
  paymentStatus: number
  paymentStatusText: string
  wishTimeSlot?: string
  isTimeAgreed?: boolean
  customer: IDispatcherCustomer
  depot: IDepot
  depotId: number
  transports: ITranportPairOrder[]
  fuelQuotes: IDispatcherOrderFuel[]
  createdOn: Date,
  actualLoadTime: Date
}

export interface IOrderFuel {
  id: number
  quoteId: number
  fuelType: string
  density: number
  price: number,
  addon: number,
  amount: number
  isFuture: boolean,
  limitId: number
}

export interface IDispatcherOrderFuel {
  id: number
  quoteId: number
  fuelType: string
  density: number
  amount: number
  isFuture: boolean,
  limitId: number
}

export interface IWaybillFuel extends IDispatcherOrderFuel {
  section: number | undefined
  temperature: number | undefined
  qualityPassport: string
}

export interface ITranportPairOrder {
  id: number
  driverId: number
  driver?: IDriver
  truckId: number
  truck?: ITransport
  pickupId?: number
  pickup?: ITransport
  wishTimeSlot?: string
  fuelAmount: number
}

export interface IOrderCreate {
  date: Date | string
  deliveryType: DeliveryType
  delivery: IDeliveryOrderCreate
  exchangeType?: ExchangeType
  customerId: number
  depotId: number
  deliveryPrice: number
  wishTimeSlot?: string
  isReserve: boolean
  transports: ITranportPairOrderCreate[]
  fuelQuoteOrders: IFuelQuoteOrder[]
  fuelFutureOrders: IFuelFutureOrder[]
  newAddresses: IDeliveryAddress[]
  newDrivers: IDriver[]
  newTransports: ITransport[]
}

export interface IOrderRecreate extends IOrderCreate {
  id: number
}

export interface IOrderUpdate extends IOrderCreate {
  id: number
}

export interface IDeliveryOrderCreate {
  deliveryAddressId: number
  pump?: boolean
  commentary?: string
}

export interface ITranportPairOrderCreate {
  driverId: number
  truckId: number
  fuelAmount: number
  pickupId?: number
  wishTimeSlot?: string
}

export interface IFuelQuoteOrder {
  id: number
  amount: number,
  limitId?: number
}

export interface IFuelFutureOrder {
  id: number
  amount: number,
  avgPrice: number,
  addon: number,
  limitId?: number | null
}

export interface IOrderChangeStatus {
  orderId: number
  orderStatus: OrderStatus
}

export interface IOrderApprove {
  orderId: number
  isApproved: boolean
}

export interface IOrderPayForOrder {
  orderId: number
  withPayment: boolean
}

export interface IOrderCoordinateTime {
  orderId: number
  isTimeAgreed?: boolean
  newSlot?: string
}

export interface IOrderLog{
  id: number
  modifiedBy: string
  modifiedByName?: string
  modifiedOn: Date
  orderId: number
  changesTypes: OrderChangeType[]
  currentValue: string
  prevValue?: string
}

export enum DeliveryType {
  Delivery,
  Pickup
}

export const deliveryTypes = [
  {
    name: "Доставка",
    type: DeliveryType.Delivery
  },
  {
    name: "Самовывоз",
    type: DeliveryType.Pickup
  }
]

export enum ExchangeType {
  Electronic,
  Paper
}

export const exchangeTypes = [
  {
    name: "Электронная",
    type: ExchangeType.Electronic
  },
  {
    name: "Бумажная",
    type: ExchangeType.Paper
  }
]

export enum OrderStatus {
  New,
  OnApproval,
  WaitingForPayment,
  IsReserve,
  DispatcherTimeCoordination,
  TimeIsSet,
  Approved,
  OnLoading,
  Shipped,
  Canceled,
  Closed,
  ClientTimeCoordination,
  OnClientApprovingTransport,
  Placed,
  NotSettedTime,
  OnApprovalDelivery
}

export enum OrderAction {
  Approve,
  PayForOrder,
  FinanceDownload,
  DispatcherTimeCoordination,
  OnApprovalDelivery,
  OnLoad,
  Ship,
  Cancel,
  Close,
  Reload,
  EditByCustomer, // редактирует водителя, транспорт, объем топлива
  EditByManager,
  ApproveTransport,
  ClientTimeCoordination,
  FillTTN,
  DownloadTTN
}

export enum OrderChangeType{
  ChangeStatus,
  ChangeDeliveryType,
  ChangeDelivery,
  ChangeExchangeType,
  ChangeDeliveryPrice,
  ChangeDepot,
  ChangePaymentSumm,
  ChangePaymentStatus,
  ChangeOrderSumm,
  ChangeOrderAmount,
  ChangePeriodTime,
  ChangeTime,
  ChangeTransport,
  ChangeFuelQoute,
  ChangeFuelFuture

}

export const ORDERS_KEY = "ORDERS_KEY"
export const ORDERS_HISTORY_KEY = "ORDERS_HISTORY_KEY"
export const ORDERS_DISPATCHER_KEY = "ORDERS_DISPATCHER_KEY"
export const ORDERS_DISPATCHER_TIME_COORDINATE_KEY = "ORDERS_DISPATCHER_TIME_COORDINATE_KEY"
