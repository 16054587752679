import { FC, useState,PureComponent, useEffect, useContext } from "react"

import { FileExcelOutlined } from "@ant-design/icons"
import { Checkbox, Col, Flex, Form, Row, Segmented, Select,Typography,Button,Tooltip } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { CartesianGrid, Legend, Line, Tooltip as ChartTooltip, LineChart, XAxis, YAxis } from "recharts"

import { CustomRangePicker } from "@/components/_Shared/CustomRangePicker"
import { CustomerContext } from "@/context/CustomerContext"
import { getAnalyticsPriceExcel } from "@/http/analytics"
import { getFuelTypeByDepot } from "@/http/fuelType"

import { periods } from "./AnalyticsPriceColumns"
import classes from  "./AnalyticsPricePage.module.scss"

import { useAnalyticsComparisonPeriodQuery, useAnalyticsPriceQuery } from "@/hook/Analytics/useAnalyticsQuery"
import { useDepotSelectListQuery } from "@/hook/Dictionaries/useDepotQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"

import { AnalyticsPeriod, IAnalyticsPrice } from "@/types/IAnalytics"
import { IFuelType } from "@/types/IFuelType"

const { Title } = Typography

const AnalyticsPricePage: FC = () => {
  const [form] = Form.useForm()
  const [activePeriod, setActivePeriod] = useState<AnalyticsPeriod | undefined>(AnalyticsPeriod.Yearly)
  const [startDate, setStartDate] = useState<Date|undefined>( undefined )
  const [startDateComparison, setStartDateComparison] = useState<Date|undefined>( undefined )
  const [endDate, setEndDate] = useState<Date|undefined>( undefined )
  const [endDateComaprison, setEndDateComaprison] = useState<Date|undefined>( undefined )
  const [depotId, setDepotId] = useState<number | undefined>(undefined)
  const [fuelTypeId, setFuelTypeId] = useState<number | undefined>(undefined)
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>([])
  const [isArbitraryPeriod, setIsArbitraryPeriod ] = useState<boolean>(false)
  const [isComparisonPeriods, setIsComparisonPeriods ] = useState<boolean>(false)
  const customerContext = useContext(CustomerContext)

  const { data: depots } = useDepotSelectListQuery()

  const { data: analyticaPriceData } = useAnalyticsPriceQuery({
    period: activePeriod,
    depotId: depotId,
    fuelTypeId: fuelTypeId,
    startDate: startDate,
    endDate: endDate,
    isComparison: isComparisonPeriods,
    startDateComparison: startDateComparison,
    endDateComparison: endDateComaprison,
    customerId: customerContext.customer?.id
  })

  const { download: excelDownload, isLoading: isExcelLoading } =
  useDownloadFile(() =>
    getAnalyticsPriceExcel({
      period: activePeriod,
      depotId: depotId,
      fuelTypeId: fuelTypeId,
      startDate: startDate,
      endDate: endDate,
      isComparison: isComparisonPeriods,
      startDateComparison: startDateComparison,
      endDateComparison: endDateComaprison,
      customerId: customerContext.customer?.id
    })
  )

  const { data:periodData } = useAnalyticsComparisonPeriodQuery(activePeriod ?? AnalyticsPeriod.Monthly)

  const onDepotChange = (id: number) => {
    if(id){
      setDepotId(id)
      getFuelTypeByDepot(id).then(response=>{
        if (response.succeeded) {
          setFuelTypes(response.response)
        }
      })
    }
    else {
      form.setFieldValue("fuelTypeId", undefined)
      setFuelTypeId(undefined)
      setDepotId(undefined)
    }
  }

  const onFuelTypeChange = (id: number) => {
    setFuelTypeId(id)
  }

  const onArbitraryCheckBoxChange = (isChecked: boolean) => {
    setIsArbitraryPeriod(isChecked)
    setStartDate(undefined)
    setEndDate(undefined)
  }

  const onChangePeriod  = (e:AnalyticsPeriod) => {
    setActivePeriod(e)
    form.setFieldValue("periodDateComparison",undefined)
    form.setFieldValue("periodDate",undefined)
  }

  const chartConfig = {
    xField: "dateText",
    yField: "price"
  }

  return (
    <>
      <Form form={form}>
      <Row>
        <Title style={{ marginLeft: "12px" }} level={3}>Динамика цен</Title>
      </Row>
      <Row>
        <Flex gap="middle" align="baseline">
          { !isArbitraryPeriod && (
            <Form.Item className={classes.formItem} name="period" label="Период">
              <Segmented
              value={activePeriod}
              options={periods}
              onChange={onChangePeriod}/>
            </Form.Item>
          )}
          {isArbitraryPeriod && (
            <Form.Item className={classes.formItem} name="rangeDates" label="Период">
            <CustomRangePicker 
            onChange={(e)=>{
              if(isComparisonPeriods){
                setStartDateComparison(e[0]?.toDate())
                setEndDateComaprison(e[1]?.toDate())
              }
              else{
                setStartDate(e[0]?.toDate())
                setEndDate(e[1]?.toDate())
              }
            }}
             style={{ width: "100%" }} />
            </Form.Item>
          )}
          {!isComparisonPeriods && 
            <Checkbox value={isArbitraryPeriod} onChange={(e=>onArbitraryCheckBoxChange(e.target.checked))}>Произвольный период</Checkbox>}
            <Tooltip title="Выгрузить в Excel" style={{ marginLeft : "auto" }}>
              <Button
              type="primary"
              disabled={false}
              onClick={excelDownload}
              icon={<FileExcelOutlined/>}
              />
            </Tooltip>
        </Flex>
      </Row>
      <Row hidden={isArbitraryPeriod}>
        <Col>
        <Form.Item
        className={classes.formItem}
        name="periodDate"
        label="Дата">
          <Select
                style={{ width:"200px" }}
                onChange={(value)=>{
                  if(value){
                    const selectedPeriod = JSON.parse(value)
                    setStartDate(selectedPeriod.startDate)
                    setEndDate(selectedPeriod.endDate)
                  }
                }}
                placeholder="переод"
                showSearch
                allowClear
                options={periodData?.response.map((periodData) => ({
                  value:  JSON.stringify({
                    startDate: periodData.startDate,
                    endDate: periodData.endDate
                  }),
                  label: periodData.periodText
                }))}
              />
        </Form.Item>
        </Col>
        <Col hidden={!isComparisonPeriods}>
        <Form.Item
        className={classes.formItem}
        name="periodDateComparison"
        label="Дата (сравнение)">
          <Select
                style={{ width:"200px" }}
                onChange={(value)=>{
                  if(value){
                    const selectedPeriod = JSON.parse(value)
                    setStartDateComparison(selectedPeriod.startDate)
                    setEndDateComaprison(selectedPeriod.endDate)
                  }
                }}
                placeholder="переод"
                showSearch
                allowClear
                options={periodData?.response.map((periodData) => ({
                  value:  JSON.stringify({
                    startDate: periodData.startDate,
                    endDate: periodData.endDate
                  }),
                  label: periodData.periodText
                }))}
              />
        </Form.Item>
        </Col>
      </Row>
      <Row>
      <Form.Item
            className={classes.formItem}
             name="depotId"
             label="Нефтебаза">
               <Select
                onClear={() => setDepotId(undefined)}
                onChange={onDepotChange}
                placeholder="Нефтебаза"
                showSearch
                allowClear
                options={depots?.response.map((depot) => ({
                  value: depot.id,
                  label: depot.name
                }))}
              />
          </Form.Item> 
      </Row>  
      <Row>
        <Form.Item
          className={classes.formItem}
          name="fuelTypeId"
          label="Тип топлива">
            <Select
            onClear={() => setFuelTypeId(undefined)}
            onChange={onFuelTypeChange}
            placeholder="Тип топлива"
            showSearch
            allowClear
            options={fuelTypes?.map((fuel) => ({
                value: fuel.id,
                label: fuel.name
              }))}
            />
          </Form.Item>
      </Row>
      <Row style={{ marginLeft: "12px" }}>
        <Checkbox onChange={(e)=>{
          setIsComparisonPeriods(e.target.checked)
          setIsArbitraryPeriod(false)
          form.setFieldValue("periodDateComparison",undefined)
        }}>Сравнение периодов</Checkbox>
      </Row>
      <Row style={{ marginTop: "12px" }}>
      <LineChart
         data={analyticaPriceData?.response}
         width={1100}
         height={400}   
         margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
          <XAxis dataKey={chartConfig.xField}  />
          <YAxis dataKey={chartConfig.yField} />
          <ChartTooltip />
          <Legend />
          <CartesianGrid stroke="#f5f5f5" />
          <Line type="monotone" name="цена"  dataKey={chartConfig.yField} stroke="#387908"/>
          {isComparisonPeriods && (
            <Line type="monotone" name="цена (сравнение)" dataKey={"priceComparison"}/>
          )}
        </LineChart>
      </Row>
      </Form>
    </>
  )
}

export default AnalyticsPricePage
