import { useMutation, useQueryClient } from "@tanstack/react-query"

import { TIME_UNLOAD_FUEL_FREE_KEY } from "@/constants/query"
import { getFreeSlotForUnload, getAvailableTimeUnloadFuel } from "@/http/timeUnloadFuel"

import { useMessage } from "@/hook/useMessage"

export const useTimeUnloadFuelMutation = () => {
    const client = useQueryClient()
    const { Success, Error, Process } = useMessage(TIME_UNLOAD_FUEL_FREE_KEY)

    const onSuccess = () => {
        client.invalidateQueries([TIME_UNLOAD_FUEL_FREE_KEY]).then()
        Success()
    }
    
    const onMutate = () => {
        Process()
    }
    
    const onError = (e: any) => {
        Error(e)
    }

    const checkOnFreeTimeUnloadMutation = useMutation({
        mutationFn: getFreeSlotForUnload,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
    })

    const getAvailableTimeUnloadFuelsMutation = useMutation({
        mutationFn: getAvailableTimeUnloadFuel,
        onError: (e) => onError(e)
    })

    return {
        checkOnFreeTimeUnloadMutation,
        getAvailableTimeUnloadFuelsMutation
    }
}