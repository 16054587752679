
import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { AnalyticsPeriod, ComparisonPeriod, IAnalyticsOption, IAnalyticsPrice } from "@/types/IAnalytics"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { DiagramAnalytics, IOrderAnalytics, IOrderAnalyticsByClientParams } from "@/types/IOrderAnalytics"
import { ITableColumn } from "@/types/ui"

export const getOrderAnalyticsByClient = (analyticsParams: IOrderAnalyticsByClientParams, params?: IRequestParams)
  : Promise<IPagingResponse<IOrderAnalytics>> =>
  client.post("/analytics/get-order-analytics", analyticsParams, {
    params: params
  })

export const getOrderAnalyticsByClientExcel = (
  analyticsParams: IOrderAnalyticsByClientParams,
  columns: ITableColumn<DiagramAnalytics>[],
  params?: IRequestParams): Promise<IResponse<IDownloadDocument>> =>
  client.post("/analytics/get-order-analytics/Excel",
    {
      optionsList: analyticsParams,
      columns: columns
    },
    {
      params: params
    })

export const getAnalyticsPrice = (params:IAnalyticsOption):Promise<IResponse<IAnalyticsPrice[]>> =>
    client.get("/Analytics/price",{ params })

export const GetPeriodForComparison = (period:AnalyticsPeriod):Promise<IResponse<ComparisonPeriod[]>> =>
    client.get(`/Analytics/comparison-period?period=${period}`)

export const getAnalyticsPriceExcel = (body: IAnalyticsOption): Promise<IResponse<IDownloadDocument>> => client.post(
    "/Analytics/price/Excel",body)

