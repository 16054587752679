import { useQuery } from "@tanstack/react-query"

import { CUSTOMER_QUERY_KEY, CUSTOMER_SELECT_LIST_QUERY_KEY } from "@/constants/query"
import { getCustomer, getCustomerById, getCustomers, getCustomersByClient, getCustomersSelectList } from "@/http/customer"

import { IRequestParams } from "@/types/api"

export const useCustomerQuery = () => {
  return useQuery({
      queryFn: () => getCustomers(),
      queryKey: [CUSTOMER_QUERY_KEY],
      retry: false
    })
}

export const useCustomersSelectListQuery = () => {
  return useQuery({
      queryFn: () => getCustomersSelectList(),
      queryKey: [CUSTOMER_SELECT_LIST_QUERY_KEY]
    })
}

export const useCustomerByClientQuery = (id: number, params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getCustomersByClient(params || {}, id),
    queryKey: [CUSTOMER_QUERY_KEY, params],
    retry: false
  })
}

export const useCustomerListQuery = (params?: IRequestParams) => {
  return useQuery({
      queryFn: () => getCustomer(params || {}),
      queryKey: [CUSTOMER_QUERY_KEY, params],
      retry: false
    })
}

export const useCustomerByIdQuery = (id: number) => {
  return useQuery({
      queryFn: () => getCustomerById(id),
      queryKey: [CUSTOMER_QUERY_KEY, id],
      enabled: id !== 0
    })
}
