import React, { FC } from "react"

import { Navigate, Route, Routes } from "react-router-dom"

import { Resource, Scope } from "@/constants/permission"
import { MainLayout } from "@/layout/MainLayout"
import { AccountMainPage } from "@/pages/Account/MainPage"
import AnalyticsOffersPage from "@/pages/Analytics/Offers/AnalyticsOffersPage"
import OrderAnalyticsPage from "@/pages/Analytics/Order/OrderAnalyticsPage"
import AnalyticsPricePage from "@/pages/Analytics/Price/AnalyticsPricePage"
import AnalyticsPurchasePage from "@/pages/Analytics/Purchase/AnalyticsPurchasePage"
import AnalyticsRefusalPage from "@/pages/Analytics/Refusal/AnalyticsRefusalPage"
import AuthoritiesById from "@/pages/AuthoritiesById/AuthoritiesById"
import { AuthoritiesPage } from "@/pages/AuthoritiesList"
import { AuthorizationError } from "@/pages/AuthorizationError"
import CustomerPage from "@/pages/Customer/CustomerPage"
import { CustomerById } from "@/pages/CustomerById"
import CustomerCategoryPage from "@/pages/CustomerCategory/CustomerCategoryPage"
import CustomerCategoryById from "@/pages/CustomerCategoryById/CustomerCategoryById"
import CustomerClientPage from "@/pages/CustomerClient/CustomerClientPage"
import CustomerClientById from "@/pages/CustomerClientById/CustomerClientById"
import { CustomerFinance } from "@/pages/CustomerFinance"
import { DeliveryAddressesPage } from "@/pages/DeliveryAddresses"
import { DepotsPage } from "@/pages/Depots"
import { DispatcherOrderById } from "@/pages/DispatcherOrderById"
import { DispatcherOrderPage } from "@/pages/DispatcherOrdersList"
import { DispatcherTimeCoordinatePage } from "@/pages/DispatcherTimeCoordinateList"
import { DriverById } from "@/pages/DriverById"
import { DriversPage } from "@/pages/Drivers"
import Finance from "@/pages/Finance/Finance"
import { FuelPage } from "@/pages/Fuel"
import { FuelArchivePage } from "@/pages/FuelArchive"
import { FuelById } from "@/pages/FuelById"
import FuelLimitById from "@/pages/FuelLimitById/FuelLimitById"
import { FuelLimitsPage } from "@/pages/FuelLimits"
import { FuelQuoteById } from "@/pages/FuelQuoteById"
import { FuelTypePage } from "@/pages/FuelType"
import { FuelTypeById } from "@/pages/FuelTypeById"
import { MainPage } from "@/pages/Main"
import { ManagerAccountMainPage } from "@/pages/ManagerAccount/MainPage"
import { NotificaionsPage } from "@/pages/Notificaiton/Index"
import { NotificationSettings } from "@/pages/NotificationSettings/Index"
import { OrderById } from "@/pages/OrderById"
import { OrderHistoryPage } from "@/pages/OrderHistory"
import { OrderPage } from "@/pages/OrderList"
import { OrderLogsById } from "@/pages/OrderLogsById"
import OrderReportPage from "@/pages/Reports/OrderReport/OrderReportPage"
import { RolesPage } from "@/pages/Role"
import TransportsPage from "@/pages/Transport/TransportsPage"
import TransportById from "@/pages/TransportById/TransportById"
import { UsersPage } from "@/pages/User"
import { UserById } from "@/pages/UserById"

import { paths } from "./paths"

import GuardRoute from "@/router/GuardRoute"

export const Router: FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={paths.orders} element={<GuardRoute resource={Resource.Order} scope={Scope.Menu} />}>
          <Route index element={<OrderPage />} />
          <Route path=":id" element={<OrderById />} />
          <Route path=":id/logs" element={<OrderLogsById/>}/>
        </Route>
        <Route path={paths.authorities} element={<GuardRoute resource={Resource.Authorities} scope={Scope.Menu} />}>
          <Route index element={<AuthoritiesPage />} />
          <Route path=":id" element={<AuthoritiesById />} />
        </Route>
        <Route path={paths.dispatcherOrders} element={<GuardRoute resource={Resource.DispatcherOrders} scope={Scope.Menu}/>}>
          <Route index element={<DispatcherOrderPage />} />
          <Route path=":id" element={<DispatcherOrderById />} />
        </Route>
        <Route path={paths.dispatcherTimeCoordinate} element={<GuardRoute resource={Resource.DispatcherOrders} scope={Scope.Menu}/>}>
          <Route index element={<DispatcherTimeCoordinatePage />} />
          <Route path=":id" element={<DispatcherOrderById />} />
        </Route>
        <Route path={paths.ordersHistory} element={<GuardRoute resource={Resource.Account} scope={Scope.Menu} />}>
          <Route index element={<OrderHistoryPage />} />
          <Route path=":id" element={<OrderById />} />
          <Route path=":id/logs" element={<OrderLogsById/>}/>
        </Route>
        <Route path={paths.customers} element={<GuardRoute resource={Resource.Customer} scope={Scope.Menu} />}>
          <Route index element={<CustomerPage />} />
        </Route>
        <Route path={paths.customers} element={<GuardRoute resource={Resource.Customer} scope={Scope.Read} />}>
          <Route path=":id" element={<CustomerById />} />
        </Route>
        <Route path={paths.customerClients} element={<GuardRoute resource={Resource.CustomerClient} scope={Scope.Menu} />}>
          <Route index element={<CustomerClientPage />} />
          <Route path=":id" element={<CustomerClientById />} />
        </Route>
        <Route path={paths.customerCategories} element={<GuardRoute resource={Resource.CustomerCategory} scope={Scope.Menu} />}>
          <Route index element={<CustomerCategoryPage />} />
          <Route path=":id" element={<CustomerCategoryById />} />
        </Route>
        <Route path={paths.fuelTypes} element={<GuardRoute resource={Resource.FuelType} scope={Scope.Menu} />}>
          <Route index element={<FuelTypePage />} />
          <Route path=":id" element={<FuelTypeById />} />
        </Route>
        <Route path={paths.fuels} element={<GuardRoute resource={Resource.Fuel} scope={Scope.Menu} />}>
          <Route index element={<FuelPage />} />
          <Route path=":id" element={<FuelById />} />
          <Route path=":fuelId/quote/:fuelQuoteId" element={<FuelQuoteById />} />
        </Route>
        <Route path={paths.fuelLimits} element={<GuardRoute resource={Resource.Fuel} scope={Scope.Menu} />}>
          <Route index element={<FuelLimitsPage />} />
          <Route path=":id" element={<FuelLimitById />} />
        </Route>
        <Route path={paths.fuelArchive} element={<GuardRoute resource={Resource.Fuel} scope={Scope.Menu} />}>
          <Route index element={<FuelArchivePage />} />
        </Route>
        <Route path={paths.drivers} element={<GuardRoute resource={Resource.Driver} scope={Scope.Menu} />}>
          <Route index element={<DriversPage />} />
          <Route path=":id" element={<DriverById />} />
        </Route>
        <Route path={paths.transport} element={<GuardRoute resource={Resource.Transport} scope={Scope.Menu} />}>
          <Route index element={<TransportsPage />} />
          <Route path=":id" element={<TransportById />} />
        </Route>
        <Route path={paths.orderReports} element={<GuardRoute resource={Resource.OrderReport} scope={Scope.Menu} />}>
          <Route index element={<OrderReportPage/>} />
        </Route>
        <Route path={paths.account} element={<GuardRoute resource={Resource.Account} scope={Scope.Menu} />}>
          <Route index element={<AccountMainPage/>} />
        </Route>
        <Route path={paths.managerAccount} element={<GuardRoute resource={Resource.ManagerAccount} scope={Scope.Menu} />}>
          <Route index element={<ManagerAccountMainPage/>} />
        </Route>
        <Route path={paths.users} element={<GuardRoute resource={Resource.User} scope={Scope.Menu} />}>
          <Route index element={<UsersPage/>} />
          <Route path=":id" element={<UserById />} />
        </Route>
        <Route path={paths.roles} element={<GuardRoute resource={Resource.Role} scope={Scope.Menu} />}>
          <Route index element={<RolesPage/>} />
        </Route>
        <Route path={paths.notificaitons} element={<GuardRoute resource={Resource.Notification} scope={Scope.Menu}/>}>
          <Route index element={<NotificaionsPage/>}/>
        </Route>
        <Route path={paths.notificationSettings} element={<GuardRoute resource={Resource.NotificationSettings} scope={Scope.Menu}/>}>
          <Route index element={<NotificationSettings/>}/>
        </Route>
        <Route path={paths.analyticsPrice} element={<GuardRoute resource={Resource.Analytics} scope={Scope.Menu}/>}>
          <Route index element={<AnalyticsPricePage/>}/>
        </Route>
        <Route path={paths.analyticsOrder} element={<GuardRoute resource={Resource.Analytics} scope={Scope.Menu}/>}>
          <Route index element={<OrderAnalyticsPage/>}/>
        </Route>
        <Route path={paths.analyticsPurchase} element={<GuardRoute resource={Resource.Analytics} scope={Scope.Menu}/>}>
          <Route index element={<AnalyticsPurchasePage/>}/>
        </Route>
        <Route path={paths.analyticsRefusal} element={<GuardRoute resource={Resource.Analytics} scope={Scope.Menu}/>}>
          <Route index element={<AnalyticsRefusalPage/>}/>
        </Route>
        <Route path={paths.analyticsOffers} element={<GuardRoute resource={Resource.Analytics} scope={Scope.Menu}/>}>
          <Route index element={<AnalyticsOffersPage/>}/>
        </Route>
        <Route path={paths.customerFinance} element={<GuardRoute resource={Resource.CustomerFinance} scope={Scope.Menu} />}>
          <Route index element={<CustomerFinance/>} />
        </Route>
        <Route path={paths.finance} element={<GuardRoute resource={Resource.Finance} scope={Scope.Menu} />}>
          <Route index element={<Finance/>} />
        </Route>
        <Route path={paths.depots} element={<GuardRoute resource={Resource.Depot} scope={Scope.Menu} />}>
          <Route index element={<DepotsPage/>} />
        </Route>
        <Route path={paths.deliveryAddresses} element={<GuardRoute resource={Resource.DeliveryAddress} scope={Scope.Menu}/> }>
          <Route index element={<DeliveryAddressesPage />} />
        </Route>
        <Route path={paths.main} element={<MainPage />} />
        <Route path={paths.authorizationError} element={<AuthorizationError />} />
        <Route path="*" element={<Navigate to={paths.main} replace />} />
      </Route>
    </Routes>
  )
}
