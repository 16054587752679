import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDeliveryAddress, IUpdteDeliveryAddressCost } from "@/types/IDeliveryAddress"


export const getAddress = (
  params: IRequestParams
): Promise<IPagingResponse<IDeliveryAddress>> =>
  client.get("/DeliveryAddresses", {
    params
  })

export const getAllAddress = (
  params: IRequestParams
): Promise<IPagingResponse<IDeliveryAddress>> =>
  client.get("/DeliveryAddresses/all", {
    params
  })

export const getAddressForOrder = (customerId: number): Promise<IResponse<IDeliveryAddress[]>> =>
  client.get(`/DeliveryAddresses/Order/${customerId}`)

export const getAddressById = (id: number): Promise<IResponse<IDeliveryAddress>> =>
  client.get(`/DeliveryAddresses/${id}`)

export const createDeliveryAddress = (body: IDeliveryAddress): Promise<IResponse<number>> =>
  client.post("/DeliveryAddresses", body)

export const updateDeliveryAddress = (body: IDeliveryAddress): Promise<IResponse<number>> =>
  client.put("/DeliveryAddresses", body)

export const updateDeliveryAddressCost = (body: IUpdteDeliveryAddressCost) : Promise<IResponse<number>>=>
  client.put("/DeliveryAddresses/Update-cost", body)

export const deleteDeliveryAddress = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/DeliveryAddresses", {
    data: {
      ids
    }
  })