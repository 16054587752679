import { FC, useMemo } from "react"

import { LogoutOutlined, UserOutlined } from "@ant-design/icons"
import { useOidc, useOidcUser } from "@axa-fr/react-oidc"
import { HubConnectionState } from "@microsoft/signalr"
import { Avatar, Button, Dropdown, MenuProps, Space } from "antd"

import { useSignalR } from "@/context/SignalRContext"

import classes from "./UserMenu.module.scss"

const LOGOUT_KEY = "logout"
const ACCOUNT_KEY = "account"

const menuItems: MenuProps["items"] = [
  // {
  //   label: "Учетная запись",
  //   key: ACCOUNT_KEY,
  //   danger: false,
  //   icon: <UserOutlined />
  // },
  {
    label: "Выйти",
    key: LOGOUT_KEY,
    danger: true,
    icon: <LogoutOutlined />
  }
]

export const UserMenu: FC = () => {
  const { oidcUser } = useOidcUser()
  const { logout } = useOidc()
  const connection = useSignalR()
 
  const userInfo = useMemo(() => {
    if (oidcUser == null) return { name: "", symbol: "" }

    if (!oidcUser.given_name && !oidcUser.family_name) {
      return {
        name: oidcUser.preferred_username,
        symbol: oidcUser.preferred_username?.toUpperCase().at(0)
      }
    }
    return {
      name: `${oidcUser.family_name} ${oidcUser.given_name}`,
      symbol: `${oidcUser.family_name?.at(0)}${oidcUser.given_name?.at(0)}`.toUpperCase()
    }
  }, [oidcUser])

  const menuClickHandler: MenuProps["onClick"] = async (event) => {

    switch (event.key) {
      case LOGOUT_KEY:
        if (connection?.state != HubConnectionState.Disconnected) {
          await connection?.stop()  
        }
        sessionStorage.clear()
        await logout()
        break
      case ACCOUNT_KEY:
        const url = process.env.REACT_APP_ACCOUNT_URL
        if(url)
          window.open(url, "_blank")
        break
      default:
        break
    }
  }

  return (
    <Dropdown className={classes.userName}
      menu={{
        items: menuItems,
        onClick: menuClickHandler
      }}
      trigger={["click"]}
    >
      <Button type="text" size="large">
        <Space align="center">
          {userInfo.name}
          <Avatar size="small">{userInfo.symbol}</Avatar>
        </Space>
      </Button>
    </Dropdown>
  )
}
