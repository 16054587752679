import { DeliveryType } from "./IOrder"

export interface IAnalyticsPrice{
    price: number
    priceComparison?: number
    dateText: string
}

export  interface IAnalyticsOption{
  period?: AnalyticsPeriod
  startDate?: Date
  startDateComparison?: Date
  endDate?: Date
  endDateComparison?: Date
  depotId?: number
  fuelTypeId?: number
  isComparison:boolean
  customerId?: number
}

export interface ComparisonPeriod{
  startDate?: Date
  endDate?: Date
  periodText?:string
}

export enum AnalyticsPeriod {
    Daily,
    Weekly,
    Monthly,
    Quarterly,
    Yearly
}