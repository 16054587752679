import React, { FC, useEffect, useMemo, useState } from "react"

import {
  ContactsOutlined,
  ExperimentOutlined,
  FileOutlined,
  FundOutlined,
  FundViewOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  ReadOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  BellOutlined,
  SettingOutlined,
  BarChartOutlined
} from "@ant-design/icons"
import { Button, Flex, Layout, Menu, Typography, Col, Badge } from "antd"
import { NavLink, useLocation } from "react-router-dom"

import { CustomerSwapper } from "@/components/Customers/CustomerSwapper"
import { Resource, Scope } from "@/constants/permission"
import { getNotReadCountNotificaitonsByCurrentUser } from "@/http/notificaiton"

import classes from "./Sidebar.module.scss"
import { LogoSidebar } from "../../LogoWrapper/Sidebar"

import { useNotReadCountNotification } from "@/hook/Notifications/useNotificaitonQuery"
import { usePermission } from "@/hook/usePermission"

import { MenuOption, MenuPermission } from "@/types/ui"

import { paths } from "@/router/paths"

const { Text } = Typography

const menuItems: any[] = [
  getItem(
    <NavLink to={paths.account}>Главная страница</NavLink>,
    paths.account,
    {
      permission: { resource: Resource.Account, scope: Scope.Menu },
      icon: <HomeOutlined /> 
    }
  ),
  getItem(
    <NavLink to={paths.ordersHistory}>Заказы</NavLink>,
    paths.ordersHistory,
    {
      permission: { resource: Resource.Account, scope: Scope.Menu },
      icon: <ShoppingCartOutlined/>
    }
  ),
  getItem(
    <NavLink to={paths.managerAccount}>Главная страница</NavLink>,
    paths.managerAccount,
    {
      permission: { resource: Resource.ManagerAccount, scope: Scope.Menu },
      icon: <HomeOutlined/>
    }
  ),
  getItem("Топливо", "fuels-menu", { icon: <ExperimentOutlined/> }, [
    getItem(
      <NavLink to={ paths.fuelTypes }>Тип топлива</NavLink>,
      paths.fuelTypes,
      {
        permission: { resource: Resource.FuelType, scope: Scope.Menu }
      }
    ),
    getItem(<NavLink to={paths.fuels}>Топливо</NavLink>, paths.fuels, {
      permission: { resource: Resource.Fuel, scope: Scope.Menu }
    }),
    getItem(<NavLink to={paths.fuelLimits}>Лимиты на топливо</NavLink>, paths.fuelLimits, {
      permission: { resource: Resource.Fuel, scope: Scope.Menu }
    }),
    getItem(<NavLink to={paths.fuelArchive}>Архив цен на топливо</NavLink>, paths.fuelArchive, {
      permission: { resource: Resource.Fuel, scope: Scope.Menu }
    })
  ]),
  getItem(<NavLink to={paths.orders}>Заказы</NavLink>, paths.orders, {
    permission: { resource: Resource.Order, scope: Scope.Menu },
    icon: <ShoppingCartOutlined />
  }),
  getItem(
    <NavLink to={paths.dispatcherOrders}>Заказы</NavLink>,
    paths.dispatcherOrders,
    {
      permission: { resource: Resource.DispatcherOrders, scope: Scope.Menu },
      icon: <ShoppingCartOutlined />
    }
  ),
  getItem(
    <NavLink to={paths.dispatcherTimeCoordinate}>Согласование времени</NavLink>,
    paths.dispatcherTimeCoordinate,
    {
      permission: { resource: Resource.DispatcherOrders, scope: Scope.Menu },
      icon: <ShoppingCartOutlined />
    }
  ),
  getItem(
    <NavLink to={paths.customerFinance}>Финансы клиента</NavLink>,
    paths.customerFinance,
    {
      permission: { resource: Resource.CustomerFinance, scope: Scope.Menu },
      icon: <FundOutlined />
    }
  ),
  getItem(<NavLink to={paths.finance}>Финансы</NavLink>, paths.finance, {
    permission: { resource: Resource.Finance, scope: Scope.Menu },
    icon: <FundOutlined />
  }),
  getItem("Справочники", "catalogs", { icon: <ReadOutlined /> }, [
    getItem(<NavLink to={paths.drivers}>Водители</NavLink>, paths.drivers, {
      permission: { resource: Resource.Driver, scope: Scope.Menu }
    }),
    getItem(<NavLink to={paths.transport}>Транспорт</NavLink>, paths.transport, {
      permission: { resource: Resource.Transport, scope: Scope.Menu }
    }),
    getItem(
      <NavLink to={paths.customerCategories}>Категории клиентов</NavLink>,
      paths.customerCategories,
      {
        permission: { resource: Resource.CustomerCategory, scope: Scope.Menu }
      }
    ),
    getItem(
      <NavLink to={paths.customerClients}>Клиенты</NavLink>,
      paths.customerClients,
      {
        permission: { resource: Resource.CustomerClient, scope: Scope.Menu }
      }
    ),
    getItem(
      <NavLink to={paths.customers}>Субклиенты</NavLink>,
      paths.customers,
      {
        permission: { resource: Resource.Customer, scope: Scope.Menu }
      }
    ),
    getItem(
      <NavLink to={paths.depots}>Нефтебазы</NavLink>,
      paths.depots,
      {
        permission: { resource: Resource.Depot, scope: Scope.Menu }
      }
    ),
    getItem(
      <NavLink to={paths.deliveryAddresses}>Адреса доставки</NavLink>,
      paths.deliveryAddresses,
      {
        permission: { resource: Resource.DeliveryAddress, scope: Scope.Menu }
      }
    )
  ]),
  getItem("Отчеты", "reports", { icon: <FundViewOutlined /> }, [
    getItem(
      <NavLink to={paths.orderReports}>Заказы</NavLink>,
      paths.orderReports,
      {
        permission: { resource: Resource.OrderReport, scope: Scope.Menu }
      }
    )
  ]),
  getItem(<NavLink to={paths.users}>Пользователи</NavLink>, paths.users, {
    permission: { resource: Resource.User, scope: Scope.Menu },
    icon: <UserOutlined />
  }),
  getItem(<NavLink to={paths.roles}>Роли</NavLink>, paths.roles, {
    permission: { resource: Resource.Role, scope: Scope.Menu },
    icon: <ContactsOutlined />
  }),
  getItem(<NavLink to={paths.notificationSettings}>Настройка уведомлений</NavLink>, paths.notificationSettings, {
    permission: { resource: Resource.NotificationSettings, scope: Scope.Menu },
    icon: <SettingOutlined />
  }),
  getItem( <NavLink to={paths.notificaitons}> Уведомления </NavLink>, paths.notificaitons, 
      {
        permission: { resource: Resource.Notification, scope: Scope.Menu },
        icon: <BellOutlined/>
      }
  ),
  getItem("Аналитика", "analytics", { icon: <BarChartOutlined/> }, [
    getItem(<NavLink to={paths.analyticsPrice}>Динамика цен</NavLink>, paths.analyticsPrice, {
      permission:
      {  resource: Resource.Analytics, scope: Scope.Menu }
    }),
    getItem(<NavLink to={paths.analyticsOrder}>Динамика заказов</NavLink>, paths.analyticsOrder, {
      permission: 
      {  resource: Resource.Analytics, scope: Scope.Menu }
    }),
    getItem(<NavLink to={paths.analyticsPurchase}>Динамика закупок</NavLink>, paths.analyticsPurchase, {
      permission: 
      {  resource: Resource.Analytics, scope: Scope.Menu }
    }),
    getItem(<NavLink to={paths.analyticsRefusal}>Динамика отказов</NavLink>, paths.analyticsRefusal, {
      permission: 
      {  resource: Resource.Analytics, scope: Scope.Menu }
    }),
    getItem(<NavLink to={paths.analyticsOffers}>Динамика предложений</NavLink>, paths.analyticsOffers, {
      permission: 
      {  resource: Resource.Analytics, scope: Scope.Menu }
    })
  ]), 
  getItem(
    <NavLink to={paths.authorities}>Доверенности</NavLink>,
    paths.authorities,
    {
      permission: { resource: Resource.Authorities, scope: Scope.Menu },
      icon: <FileOutlined />
    }
  )
]

const openKeysMap: Record<string, string[]> = {
  "fuel-types": ["fuels-menu", "fuel-types"],
  "fuel-archive": ["fuels-menu", "fuel-archive"],
  account: ["client-personal", "account"],
  "order-history": ["client-personal", "order-history"],
  "manager-account": ["manager-account"],
  orders: ["orders"],
  "order-reports": ["reports", "order-reports"],
  "customer-clients": ["catalogs", "customer-clients"],
  fuels: ["fuels-menu", "fuels"],
  "fuel-limits": ["fuels-menu", "fuel-limits"],
  customers: ["catalogs", "customers"],
  "customer-categories": ["catalogs", "customer-categories"],
  drivers: ["catalogs", "drivers"],
  transports: ["catalogs", "transports"],
  users: ["users"],
  roles: ["roles"],
  finance: ["finance"],
  customerFinance: ["customer-finance"],
  "delivery-addresses" : ["catalogs", "delivery-addresses"]
}


export const Sidebar: FC = React.memo(() => {
  const location = useLocation()
  const [keyPath, setKeyPath] = useState<string>(
    location.pathname.split("/")[1] || ""
  )
  const { hasPermission } = usePermission()

  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [shownLogo, setShownLogo] = useState<boolean>(true)
  const [currentCustomerName, setCurrentCustomerName] = useState<string>("")
  const [countNotReadNotificaitons, setCountNotReadNotificaitons] = useState<number>(0)

  const { data, isLoading } = useNotReadCountNotification()

  const toggleCollapsed = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed)
    setShownLogo(!isCollapsed)
  }

  useEffect(() => {
    if (location.pathname) {
      const key = location.pathname.split("/")[1]
      if (key) setKeyPath(key)
    }
  }, [location.pathname])

  useEffect(()=>{
    setCountNotReadNotificaitons(data?.response ?? 0)
  },[data])

  const menus = useMemo(() => {
    const generateMenuItems = (menuItems: any): any => {
      if (!menuItems) return
      const items = []

      for (const menu of menuItems) {
        if (
          menu.permission &&
          !hasPermission(menu.permission.resource, menu.permission.scope)
        )
          continue

        const key: string = menu.key
        const children = generateMenuItems(menu.children) as any[]
        if (children && !children.some((x) => x)) {
          continue
        }

        if (key == paths.notificaitons) {
          items.push({
            label:
             <NavLink to={paths.notificaitons} className={classes["navLink-flex"]}>
                Уведомления
                <Badge className={classes["margin-badge"]} size="small" count={data?.response} overflowCount={100}/>
              </NavLink>,
            key: key.includes("/") ? key.split("/")[1] : key,
            icon: menu.icon,
            children: children
          })
        }
        else{
          items.push({
            label: menu.label,
            key: key.includes("/") ? key.split("/")[1] : key,
            icon: menu.icon,
            children: children
          })
        }
      }

      return items
    }

    return generateMenuItems(menuItems)
  }, [hasPermission, countNotReadNotificaitons])

  return (
    <Layout.Sider
      breakpoint="lg"
      trigger={null}
      width={300}
      className={classes.sidebar}
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      onBreakpoint={(broken) => {
        setShownLogo(!broken)
      }}
    >
      <Flex
        justify="space-between"
        align="center"
        className={classes.logowrapper}
      >
        <Button onClick={() => toggleCollapsed(!collapsed)}>
          {collapsed ? <MenuOutlined /> : <MenuFoldOutlined />}
        </Button>
        {hasPermission(Resource.Account, Scope.Menu) ?
        <CustomerSwapper collapsed={ collapsed } setCurrentCustomerName={setCurrentCustomerName}/>  : <LogoSidebar shown={shownLogo} />}
      </Flex>
      <>
      {hasPermission(Resource.Account, Scope.Menu) &&
      <Col className={classes.customerNameCol}>
      <Flex align="center" className={classes.row}>
        <UserOutlined className={classes.icon}></UserOutlined>
        <Text hidden={collapsed} className={classes.customerName}>
          {currentCustomerName}
        </Text>
     </Flex>
    </Col> }
      </>
      <Menu
        className={classes.menu}
        theme="dark"
        mode="inline"
        defaultOpenKeys={openKeysMap[keyPath]}
        selectedKeys={[keyPath]}
        items={menus}
      />
    </Layout.Sider>
  )
})

function getItem(
  label: React.ReactNode,
  key: React.Key,
  options?: MenuOption,
  children?: MenuPermission[]
): MenuPermission {
  return {
    key,
    children,
    label,
    icon: options?.icon,
    permission: options?.permission
  } as MenuPermission
}
