import { useEffect, useState } from "react"

import dayjs from "dayjs"

import { FieldData } from "@/components/_Shared/FilterWrapper"

import { useCustomerCategorySelectListQuery } from "@/hook/Dictionaries/Customers/useCustomerCategoryQuery"
import { useFilterStorage } from "@/hook/useFilterStorage"

import { CUSTOMER_CLIENTS_KEY } from "@/types/ICustomerClient"
import { FilterField, FilterFieldOperator, FilterFieldType } from "@/types/ui"


export const useCustomerClientFilters = () => {
  const [filters, setFilters] = useState<FilterField[]>([])

  const { getFilter } = useFilterStorage({
    key: CUSTOMER_CLIENTS_KEY
  })

  const { data: categories } = useCustomerCategorySelectListQuery()
  const oldFilters: FieldData[] = getFilter()

  useEffect(() => {
    setFilters([
      ...CustomerClientFilters,
        {
            text: "Категория",
            name: "CustomerCategoryId",
            type: FilterFieldType.Select,
            values: categories?.response.map(r=> ({ id: r.value, name: r.label }))
        }
    ])
  }, [categories])

  return {
    filters: filters.map(f => {
      const currentFilter = oldFilters.find(i => (i.name as string[])[0] === f.name)

      if (!currentFilter || !currentFilter.value) {
        return f
      }

      return {
        ...f, value: f.type === FilterFieldType.Date
          ? dayjs(new Date(currentFilter.value as string))
          : currentFilter.value
      }
    })
  }
}

const CustomerClientFilters: FilterField[] = [
  {
    text: "Наименование",
    name: "name",
    type: FilterFieldType.Text,
    operator: FilterFieldOperator.Contains
  },
  {
    text: "ФИО отвественного лица",
    name: "responsiblePersonName",
    type: FilterFieldType.Text,
    operator: FilterFieldOperator.Contains
  },
  {
    text: "Телефон",
    name: "phone",
    type: FilterFieldType.Text,
    operator: FilterFieldOperator.Contains
  }
]
