import { FC } from "react"

const AnalyticsPurchasePage:FC = ()=>{
    return (
        <div>
            <h2>Динамика закупок</h2>
        </div>
    )
}

export default AnalyticsPurchasePage