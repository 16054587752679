export enum Resource {
  Account = "account",

  ManagerAccount = "manager-account",

  Driver = "drivers",
  FuelType = "fuel-types",
  Transport = "transports",
  Countries = "countries",

  Customer = "customers",
  CustomerClient = "customer-clients",
  CustomerCategory = "customer-categories",

  Depot = "depots",
  Fuel = "fuels",
  FuelQuote = "fuel-quotes",
  FuelCustomer = "fuel-customers-price",
  FuelCustomerCategory = "fuel-customer-categories-price",
  Order = "orders",
  DeliveryAddress = "delivery-address",

  OrderReport = "order-reports",

  User = "users",
  Role = "roles",

  Finance = "finance",
  CustomerFinance = "customer-finance",

  CustomerByClient = "customers-by-client",
  UserByCustomer = "users-by-customer",

  DispatcherOrders = "dispatcher-orders",
  Authorities = "authorities", // внутренние доверенности

  Notification = "notifications",
  NotificationSettings = "notification-settings",
  Analytics = "analytics"
}

export enum Scope {
  Create = "create",
  Read = "read",
  PartialRead = "partial-read",
  Update = "update",
  Delete = "delete",
  Actions = "actions",
  ReadAll = "read-all",
  PartialUpdate = "partial-update",
  AllActions = "all-actions",
  Menu = "menu"
}
