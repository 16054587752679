
import { AnalyticsPeriod } from "@/types/IAnalytics"

export const periods = [
  {
    label: "Год",
    value: AnalyticsPeriod.Yearly
  },
  {
    label: "Квартал",
    value: AnalyticsPeriod.Quarterly
  },
  {
    label: "Месяц",
    value: AnalyticsPeriod.Monthly
  },
  {
    label: "Неделя",
    value: AnalyticsPeriod.Weekly
  }
]
