import { useState } from "react"

import { Pie, PieChart, Sector } from "recharts"

import { DiagramAnalytics } from "@/types/IOrderAnalytics"


export interface AnalyticsPieChartProps {
  chartWidth: number
  chartHeight: number
  data?: DiagramAnalytics[]
  dataKey: string
  name: string
  nameKey: string
  fill: string
}


export const AnalyticsPieChart = (props: AnalyticsPieChartProps) => {
  const {
    chartWidth,
    chartHeight,
    data,
    dataKey,
    name,
    nameKey,
    fill } = props
  const [activePieIndex, setActivePieIndex] = useState<number>()
  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      period,
      value } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? "start" : "end"

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{period}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {value}
        </text>
      </g>
    )
  }


  const onPieEnter = (_: any, index: number) => {
    setActivePieIndex(index)
  }

  return (
    <PieChart width={chartWidth} height={chartHeight}>
      <Pie
        data={data}
        dataKey={dataKey}
        name={name}
        nameKey={nameKey}
        fill={fill}
        outerRadius={125}
        label={activePieIndex === undefined}
        activeIndex={activePieIndex}
        activeShape={renderActiveShape}
        onMouseEnter={onPieEnter}
        onMouseLeave={() => setActivePieIndex(undefined)}/>
    </PieChart>
  )
}