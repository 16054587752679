import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts"

import { DiagramAnalytics } from "@/types/IOrderAnalytics"


export interface AnalyticsLineChartProps {
  data?: DiagramAnalytics[]
  dataKey: string
  name: string
}

export const AnalyticsLineChart = (props: AnalyticsLineChartProps) => {
  const { data, dataKey, name } = props


  return (
    <LineChart width={700} height={400} data={data}
               margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
      <XAxis dataKey="period"/>
      <YAxis/>
      <Tooltip/>
      <Legend/>
      <CartesianGrid stroke="#f5f5f5"/>
      <Line type="monotone" dataKey={dataKey} name={name} stroke="#387908"/>
    </LineChart>
  )
}
