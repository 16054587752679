import { useMutation, useQueryClient } from "@tanstack/react-query"

import { FUEL_LIMITS_QUERY_KEY } from "@/constants/query"
import { createFuelLimit, deleteFuelLimits, editFuelLimitSettingsCategory, updateFuelLimit } from "@/http/fuelLimit"

import { useMessage } from "@/hook/useMessage"

export const useFuelLimitsMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(FUEL_LIMITS_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([FUEL_LIMITS_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createFuelLimit,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateFuelLimit,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteFuelLimits,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const editFuelLimitSettingsMutation = useMutation({
    mutationFn: editFuelLimitSettingsCategory,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    editFuelLimitSettingsMutation
  }
}
