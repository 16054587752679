import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { INotification, IUpdateReadStatusNotifications } from "@/types/INotificaiton"

export const getNotificaitonsByCurrentUser = (params: IRequestParams): Promise<IPagingResponse<INotification>> =>
     client.get("/Notifications",{ params })

export const getNotReadNotificaitonsByCurrentUser = (countRecord?: number): Promise<IResponse<INotification[]>> =>
     client.get(`/Notifications/not-read?countRecord=${countRecord ?? undefined}`)

export const getNotReadCountNotificaitonsByCurrentUser = (): Promise<IResponse<number>> =>
     client.get("/Notifications/not-read-count")

export const updateReadStatusForNotifications = (body: IUpdateReadStatusNotifications): Promise<IResponse<boolean>> => 
     client.put("/Notifications/read-status", body) 