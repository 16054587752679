import { FC, useEffect, useState } from "react"

import { Checkbox, Divider, Flex, Form, Segmented, Select } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import dayjs, { Dayjs } from "dayjs"

import { CustomRangePicker } from "@/components/_Shared/CustomRangePicker"
import { Table } from "@/components/_Shared/Table"
import { AnalyticsLineChart } from "@/components/Analytics/AnalyticsLineChart"
import { AnalyticsPieChart } from "@/components/Analytics/AnalyticsPieChart"
import { Resource } from "@/constants/permission"
import { getOrderAnalyticsByClientExcel } from "@/http/analytics"
import { orderAnalyticsColumns } from "@/pages/Analytics/Order/OrderAnalyticsColumns"

import classes from "./OrderAnalyticsPage.module.scss"

import { useOrderAnalyticsByClientQuery } from "@/hook/Analytics/useAnalyticsQuery"
import { useDepotSelectListQuery } from "@/hook/Dictionaries/useDepotQuery"
import { useFuelTypeQuery } from "@/hook/Dictionaries/useFuelTypeQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useTable } from "@/hook/useTable"

import { IPagingResponse } from "@/types/api"
import { IDepot } from "@/types/IDepot"
import { IFuelType } from "@/types/IFuelType"
import { DiagramAnalytics } from "@/types/IOrderAnalytics"
import { Period } from "@/types/IOrderReport"
import { ITableColumn } from "@/types/ui"

import { formatFuelAmount } from "@/utils/formatNumber"

const periods = [
  {
    label: "Год",
    value: Period.Yearly
  },
  {
    label: "Квартал",
    value: Period.Quarterly
  },
  {
    label: "Месяц",
    value: Period.Monthly
  },
  {
    label: "Неделя",
    value: Period.Weekly
  }
]

interface IDisplayUnit {
  value: string
  label: string
  bigLabel: string
}

const displayUnits = [
  {
    value: "orderAmount",
    label: "Тонны",
    bigLabel: "Объем заказов (тонн)"
  },
  {
    value: "orderSum",
    label: "Рубли",
    bigLabel: "Сумма заказов"
  },
  {
    value: "orderSumMillions",
    label: "Рубли (млн)",
    bigLabel: "Сумма заказов (млн)"
  }
]

interface Options {
  depotId: number | undefined
  fuelTypeId: number | undefined
  startDate: string | undefined
  endDate: string | undefined
  activePeriod: Period | undefined
}

const OrderAnalyticsPage: FC = () => {
  const [optionsList, setOptionsList] = useState<Options[]>(() => {
    return [{
      depotId: undefined,
      fuelTypeId: undefined,
      startDate: undefined,
      endDate: undefined,
      activePeriod: Period.Yearly
    }]
  })
  const [compare, setCompare] = useState(false)
  const [displayUnit, setDisplayUnit] = useState<IDisplayUnit>(displayUnits[0])
  const [allColumns] = useState<ITableColumn<DiagramAnalytics>[]>(orderAnalyticsColumns(undefined))
  const [initialColumns, setInitialColumns] =
    useState<ITableColumn<DiagramAnalytics>[]>(orderAnalyticsColumns(displayUnit.value))

  const { columns, pagination, filter, order } = useTable({
    initialColumns: initialColumns
  })

  useEffect(() => {
    setInitialColumns(orderAnalyticsColumns(displayUnit.value))
  }, [displayUnit, compare])

  const { data: depots } = useDepotSelectListQuery()
  const { data: fuelTypes } = useFuelTypeQuery()
  const { data: analyticsData, isLoading } = useOrderAnalyticsByClientQuery({
      optionsList: optionsList
    },
    {
      Page: pagination.page,
      PageSize: pagination.pageSize,
      Filter: filter.filter,
      OrderBy: order.orderBy
    }
  )

  const [analyticsMapped, setAnalyticsMapped ] = useState<DiagramAnalytics[]>()

  const { download: excelDownload, isLoading: isExcelLoading } = useDownloadFile(() => getOrderAnalyticsByClientExcel({
      optionsList: optionsList
    },
    (() => {
      const inactiveColumns =
        allColumns.filter(c => !columns.data.map(c => c.key).includes(c.key))
          .map(c => ({ ...c }))

      inactiveColumns.forEach(c => c.isVisible = false)

      return [...columns.data, ...inactiveColumns]
    })(),
    {
      Page: pagination.page,
      PageSize: pagination.pageSize,
      Filter: filter.filter,
      OrderBy: order.orderBy
    }
  ))

  useEffect(() => {
    setAnalyticsMapped(analyticsData?.response.data.map(o => ({
      period: o.period,
      orderCount: o.orderCount,
      orderAmount: formatFuelAmount(o.orderAmount),
      orderSum: o.orderSum,
      orderSumMillions: Number((o.orderSum / 1000000).toFixed(3))
    })))
  }, [analyticsData])

  const onCompareChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked
    const newOptionsCount = checked ? 2 : 1

      setOptionsList(prev => {
        const newOptionsList = new Array<Options>()
        for (let i = 0; i < newOptionsCount; i++) {
          newOptionsList.push({
            depotId: undefined,
            fuelTypeId: undefined,
            startDate: undefined,
            endDate: undefined,
            activePeriod: checked ? undefined : Period.Yearly
          })
        }

        return newOptionsList
      })
    setCompare(checked)
  }

  const getOnFuelTypeChangeFunction = (optionsId: number) => {
    if (optionsId < 1)
      return

    return (fuelTypeId: number | undefined) => {
      setOptionsList(prev => {
        const newOptions = [...prev]
        const thisOptions = newOptions[optionsId - 1]
        thisOptions.fuelTypeId = fuelTypeId
        return newOptions
      })
    }
  }

  const getOnRangeChangeFunction = (optionsId: number) => {
    if (optionsId < 1)
      return

    return (range: [start: Dayjs | null, end: Dayjs | null]) => {
      if (!range || range.length === 2) {
        setOptionsList(prev => {
          const newOptions = [...prev]
          const thisOptions = newOptions[optionsId - 1]
          thisOptions.startDate = range ? range[0]?.format("YYYY-MM-DD") : undefined
          thisOptions.endDate = range ? range[1]?.format("YYYY-MM-DD") : undefined
          thisOptions.activePeriod = undefined
          return newOptions
        })
      }
    }
  }

  const getOnPeriodChangeFunction = (optionsId: number) => {
    if (optionsId < 1)
      return

    return (period: Period) => {
      if (period) {
        setOptionsList(prev => {
          const newOptions = [...prev]
          const thisOptions = newOptions[optionsId - 1]
          thisOptions.startDate = undefined
          thisOptions.endDate = undefined
          thisOptions.activePeriod = period
          return newOptions
        })
      }
    }
  }

  const getOnDepotChangeFunction = (optionsId: number) => {
    if (optionsId < 1)
      return

    return (depotId: number | undefined) => {
      setOptionsList(prev => {
        const newOptions = [...prev]
        const thisOptions = newOptions[optionsId - 1]
        thisOptions.depotId = depotId
        return newOptions
      })
    }
  }

  return (
    <div>
      <h2>Динамика заказов</h2>
      <Flex gap="small" align="flex-start" vertical>
        <Flex align="center" gap="middle">
          <Select
            className={classes["unit-switch"]}
            onSelect={(_, tuple) => setDisplayUnit(tuple)}
            placeholder="Отображение"
            value={displayUnit.value}
            options={displayUnits}
          />
          <Checkbox
            value={compare}
            onChange={onCompareChange}>
            Сравнить
          </Checkbox>
        </Flex>
        <Flex gap="large">
          <AnalyticsSettings
            fuelTypes={fuelTypes}
            depots={depots?.response}
            options={optionsList[0]}
            onRangeChange={getOnRangeChangeFunction(1)}
            onPeriodChange={getOnPeriodChangeFunction(1)}
            onChosenFuelTypeChange={getOnFuelTypeChangeFunction(1)}
            onChosenDepotChange={getOnDepotChangeFunction(1)}
            isComparison={compare}/>
          {
            compare &&
            <Divider className={classes["comparison-options-divider"]} type="vertical"/>
          }
          {
            compare &&
              <AnalyticsSettings
                fuelTypes={fuelTypes}
                depots={depots?.response}
                options={optionsList[1]}
                onRangeChange={getOnRangeChangeFunction(2)}
                onPeriodChange={getOnPeriodChangeFunction(2)}
                onChosenFuelTypeChange={getOnFuelTypeChangeFunction(2)}
                onChosenDepotChange={getOnDepotChangeFunction(2)}
                isComparison={compare}/>
          }
        </Flex>
        <Flex align="center">
          <AnalyticsLineChart
            data={analyticsMapped}
            dataKey={displayUnit.value}
            name={displayUnit.bigLabel}/>
          <AnalyticsPieChart
            chartWidth={730}
            chartHeight={400}
            name={displayUnit.bigLabel}
            data={analyticsMapped}
            dataKey={displayUnit.value}
            nameKey={displayUnit.label}
            fill="#8884d8"/>
        </Flex>
        <Table
          dataSource={analyticsMapped}
          columns={columns.data}
          download={{
            onDownload: excelDownload,
            isLoading: isExcelLoading
          }}
          isSelectable={false}
          rowKey="period"
          isLoading={isLoading}
          onUpdateColumns={columns.onColumnChange}
          pagination={{
            currentPage: pagination.page,
            pageSize: pagination.pageSize,
            total: analyticsData?.response.count,
            onPageChange: pagination.onPageChange,
            onPageSizeChange: pagination.onPageSizeChange
          }}
          onSorterChange={order.setOrderBy}
          permission={{
            resource: Resource.OrderReport
          }}
        />
      </Flex>
    </div>
  )
}

interface IAnalyticsSettingsProps {
  fuelTypes: IPagingResponse<IFuelType> | undefined
  depots: IDepot[] | undefined
  options: Options
  onRangeChange: ((range: [start: Dayjs | null, end: Dayjs | null]) => void) | undefined
  onPeriodChange: ((period: Period) => void) | undefined
  onChosenFuelTypeChange: ((fuelTypeId: number | undefined) => void) | undefined
  onChosenDepotChange: ((depotId: number | undefined) => void) | undefined
  isComparison: boolean
}

const AnalyticsSettings = (props: IAnalyticsSettingsProps) => {
  const {
    fuelTypes,
    options,
    depots,
    onRangeChange,
    onPeriodChange,
    onChosenFuelTypeChange,
    onChosenDepotChange,
    isComparison } = props

  const {
    startDate,
    endDate,
    activePeriod } = options

  return (
    <Flex vertical>
      <Flex gap="middle">
        <Form.Item>
          <CustomRangePicker
            value={[startDate ? dayjs(startDate) : undefined, startDate ? dayjs(endDate) : undefined]}
            onChange={onRangeChange}
          />
        </Form.Item>
        {
          !isComparison &&
            <Segmented
                options={periods}
                value={activePeriod}
                onChange={onPeriodChange}
            />
        }
      </Flex>
      <Flex gap="middle" align="baseline">
        <Form.Item
          label="Вид топлива"
        >
          <Select
            onClear={() => onChosenFuelTypeChange && onChosenFuelTypeChange(undefined)}
            onChange={onChosenFuelTypeChange}
            value={options.fuelTypeId}
            placeholder="Вид топлива"
            showSearch
            allowClear
            options={fuelTypes?.response.data.map(d => ({
              value: d.id,
              label: d.name
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Нефтебаза"
        >
          <Select
            onClear={() => onChosenDepotChange && onChosenDepotChange(undefined)}
            onChange={onChosenDepotChange}
            value={options.depotId}
            placeholder="Нефтебаза"
            showSearch
            allowClear
            options={depots?.map(d => ({
              value: d.id,
              label: d.name
            }))}
          />
        </Form.Item>
      </Flex>
    </Flex>
  )
}

export default OrderAnalyticsPage