import { useQuery } from "@tanstack/react-query"

import { ANALYTICS_ORDERS_QUERY_KEY, ANALYTICS_PRICE_QUERY_KEY,ANALYTICS_COMPARISON_PERIOD_QUERY_KEY } from "@/constants/query"
import { getAnalyticsPrice, GetPeriodForComparison } from "@/http/analytics"
import { getOrderAnalyticsByClient } from "@/http/analytics"

import { IRequestParams } from "@/types/api"
import { AnalyticsPeriod, IAnalyticsOption } from "@/types/IAnalytics"
import { IOrderAnalyticsByClientParams } from "@/types/IOrderAnalytics"


export const useOrderAnalyticsByClientQuery = (analyticsParams: IOrderAnalyticsByClientParams, params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getOrderAnalyticsByClient(analyticsParams),
    queryKey: [ANALYTICS_ORDERS_QUERY_KEY, analyticsParams],
    retry: false
  })
}

export const useAnalyticsPriceQuery = (params: IAnalyticsOption) => {
  return useQuery({
    queryFn: () => getAnalyticsPrice(params),
    queryKey: [ANALYTICS_PRICE_QUERY_KEY, params],
    retry: false
  })
}

export const useAnalyticsComparisonPeriodQuery = (period: AnalyticsPeriod) => {
  return useQuery({
    queryFn: () => GetPeriodForComparison(period),
    queryKey: [ANALYTICS_COMPARISON_PERIOD_QUERY_KEY, period],
    retry: false
  })
}