import { IFuelQuote } from "./IFuelQuote"
import { IFuelType } from "./IFuelType"

export interface IFuelOrder {
  id: number
  quoteId: number
  density: number
  price: number
  avgPrice: number
  addon: number,
  limit: number
  limitId?: number
  fuelCommentary?: string
  isFuture: boolean
}

export interface IFuelRecord {
  id: number
  fuelType: string
  density: number
  todayPrice: number
	tomorrowPrice: number
  dayAfterTomorrowPrice: number
	todayDailyDelta: number
	tomorrowDailyDelta: number
	dayAfterTomorrowDelta: number,
  todayLimit: number, 
  tomorrowLimit: number, 
  dayAfterTomorrowLimit: number
  todayEndDate: Date,
  tomorrowEndDate: Date,
  dayAfterTomorrowEndDate: Date
  todayStartDate: Date, 
  tomorrowStartDate: Date, 
  dayAfterTomorrowStartDate: Date

}

export interface IFuelRecordAmount {
  id: number
  fuelType: string
  todayAmout: number
  todayAmount: number
  tomorrowAmount: number
}

export interface IFuel {
  id: number
  isArchived: boolean
  orderNumber: number
  fuelType: IFuelType
  depot: IFuelDepot
  fuelQuotes: IFuelQuote[]
}

export interface IFuelList {
  id: number
  isArchived: boolean
  orderNumber: number
  fuelType: IFuelType
  depot: IFuelDepot
  lastQuote?: IFuelQuote
}

export interface IFuelDepot {
  id: number
  name: string
}

export interface IFuelCreate {
  fuelTypeId: number
  depotId: number
  isArchived: boolean
  orderNumber: number
}

export interface IFuelUpdate extends IFuelCreate {
	id: number
}

export const getTransformedFuelDensity = (density: number | null | undefined) => {
  return density ? (density / 1000).toString().replace(".", ",") : "-"
}

export const getTransformedFuelTemperature = (temperature: number | null | undefined) => {
  return (temperature !== undefined) && (temperature !== null)
    ? temperature.toFixed(1).toString().replace(".", ",")
    : "-"
}

export const getTransformedFuelSection = (section: number | null | undefined) => {
  return (section !== undefined) && (section !== null)
    ? section > 0 ? section.toString() : "-"
    : "-"
}

export const getTransformedFuelQualityPassport = (qualityPassport: string | null | undefined) => {
  return qualityPassport ? qualityPassport !== "" ? qualityPassport : "-" : "-"
}

export const getTransformedIsArchived = (isArchived: boolean | null | undefined) => {
  return isArchived ? "Да" : "Нет"
}

export const convertFuelAmountToLitres = (amount:number , density: number | null | undefined, digits: number = 6): number => {
  if (density) {
    let result =  amount / (density / 1000) * 1000
    return parseFloat(result.toFixed(digits))
  }
  return 0
}

export const convertFuelAmountToTonn = (amountLitres:number , density: number | null | undefined, digits: number = 6): number => {
  if (density) {
    let result = amountLitres * (density / 1000) / 1000
    return parseFloat(result.toFixed(digits))
  }
  return 0
}

export const FUELS_KEY = "FUELS_KEY"
