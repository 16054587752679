import { Col, Row, Typography, Alert } from "antd"

import classes from "./OrderDeliveryTable.module.scss"

import { IDelivery } from "@/types/IDelivery"

const { Text } = Typography

export interface OrderDeliveryTable {
    delivery: IDelivery | undefined
    deliveryPrice? : number | undefined
    deliveryDefaultPrice?: number
    showPrice: boolean
}

export const OrderDeliveryTable = (props: OrderDeliveryTable) => {
    return (
        <>
          <Row className={classes["order-row"]} gutter={16}>
            <Col xs={24} md={24}>
              <Row gutter={[12, 6]}>
                <Col span={24}>
                  <Text type="secondary">Адрес доставки:</Text>
                </Col>
                <Col span={24}>
                  <Text className={classes["f16"]}>
                    {props.delivery?.deliveryAddress?.fullAddress}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          {
            props.showPrice &&
              <Row className={classes["order-row"]} gutter={16}>
                <Col xs={24} md={24}>
                  <Row gutter={[12, 6]}>
                    <Col span={24}>
                        <Text type="secondary">Стоимость доставки:</Text>
                    </Col>
                    <Col span={24}>
                      {
                        props.deliveryPrice && props.deliveryPrice > 0 ?
                          <Text className={classes["f16"]}>{props.deliveryPrice}</Text> :
                          <>
                            <Text className={classes["f16"]}>{props.deliveryDefaultPrice}</Text>
                            <Alert  message="Используется стандартное значение, цена  за доставку будет установлена позже" type="warning" showIcon/>
                          </>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
          }
          <Row className={classes["order-row"]} gutter={16}>
            <Col xs={24} md={24}>
              <Row gutter={[12, 6]}>
                <Col span={24}>
                  <Text type="secondary">Насос:</Text>
                </Col>
                <Col span={24}>
                  <Text className={classes["f16"]}>
                    {props.delivery?.pump ? "Да" : "Нет"}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={classes["order-row"]} gutter={16}>
            <Col xs={24} md={24}>
              <Row gutter={[12, 6]}>
                <Col span={24}>
                  <Text type="secondary">Комментарий к доставке:</Text>
                </Col>
                <Col span={24}>
                  <Text className={classes["f16"]}>
                    {props.delivery?.commentary
                      ? props.delivery?.commentary
                      : "Нет"}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
    )
}