import { DeliveryType } from "./IOrder"

export interface IOrderReport {
  period: string
  orderCount: string
  first: string
  orderSum: number
  orderAmount: number
}

export interface IOrderReportOption {
  period: Period
  startDate?: Date
  endDate?: Date
  customerId?: number
  deliveryType?: DeliveryType
  isNotShipped?: boolean
  Page?: number
  PageSize?: number
  OrderBy?: string
  Filter?: string
}

export enum Period {
  Daily,
  Weekly,
  Monthly,
  Quarterly,
  Yearly
}

export const ORDER_REPORT_KEY = "ORDER_REPORT_KEY"
