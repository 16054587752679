import { IDriver } from "@/types/IDriver"
import { ITransport } from "@/types/ITransport"

export const formatDriver = (driver?: IDriver) => {
	return `${driver?.fullName ?? ""}`
}

export const formatTransport = (transport?: ITransport) => {
	return transport ? `${transport.type}, ${transport.model}, ${transport.number}, ${(transport.liter ?? "не установлено") + " Л"}` : ""
}

export const formatNulableValue = (value: string | null | undefined) => {
	return value ? value : "-"
}

export const formatLongStringValue = (value: string | null | undefined) => {
	const maxVisibaleLen = 80
	const moreDots = "..."

	return !value ? 
		formatNulableValue(value) : 
		value?.length > maxVisibaleLen ? value.substring(0, maxVisibaleLen) + moreDots : value
}
